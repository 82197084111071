import axios from 'axios';
import Cookies from "js-cookie";

let util = {};

const ajaxUrl = '';

util.ajax = axios.create({
  baseURL: ajaxUrl,
  timeout: 120000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

util.ajaxJSON = axios.create({
  baseURL: ajaxUrl,
  timeout: 120000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

util.postJSONRequest = async function (url, formdata, ctx) {
  try {
    const response = await util.ajaxJSON.post(url, formdata);
    return {
      data: response.data,
      error: null,
      status: response.status
    }
  } catch (error) {
    console.log(error);
    util._errorhandler(error);
    return {
      error: error,
    }
  }
};

util.getRequest = async function (url, query) {
  try {
    const response = await util.ajax.get(url, {
      params: query
    });
    return {
      data: response.data,
      error: null,
      status: response.status
    }
  } catch (error) {
    console.log(error);
    util._errorhandler(error);
    return {
      error: error
    }
  }
}

util.postRequest = async function (url, formdata, ctx) {
  try {
    const response = await util.ajax.post(url, formdata);
    return {
      data: response.data,
      error: null,
      status: response.status
    }
  } catch (error) {
    console.log(error);
    util._errorhandler(error);
    return {
      error: error,
    }
  }
}

util._errorhandler = function (error) {
  if (error.response && error.response.status) {
    if (error.response.status == 500) {
      console.log(error);
    }
  }
}

util._signout = function () {
  Cookies.remove("access");
  localStorage.pageOpenedList = [];
  window.URAVue.$router.push({
    name: "login"
  });
}

util.inOf = function (arr, targetArr) {
  let res = true;
  arr.forEach(item => {
    if (targetArr.indexOf(item) < 0) {
      res = false;
    }
  });
  return res;
};

util.oneOf = function (ele, targetArr) {
  if (targetArr.indexOf(ele) >= 0) {
    return true;
  } else {
    return false;
  }
};

util.title = function (title) {
  title = title ? title : 'VR3D';
  window.document.title = title;
};

util.getRouterObjByName = function (routers, name) {
  if (!name || !routers || !routers.length) {
    return null;
  }
  // debugger;
  let routerObj = null;
  for (let item of routers) {
    if (item.name === name) {
      return item;
    }
    routerObj = util.getRouterObjByName(item.children, name);
    if (routerObj) {
      return routerObj;
    }
  }
  return null;
};

util.handleTitle = function (vm, item) {
  if (typeof item.title === 'object') {
    return vm.$t(item.title.i18n);
  } else {
    return item.title;
  }
};

util.setCurrentPath = function (vm, name) {
  let title = '';
  let isOtherRouter = false;
  vm.$store.state.app.routers.forEach(item => {
    if (item.children.length === 1) {
      if (item.children[0].name === name) {
        title = util.handleTitle(vm, item);
        if (item.name === 'otherRouter') {
          isOtherRouter = true;
        }
      }
    } else {
      item.children.forEach(child => {
        if (child.name === name) {
          title = util.handleTitle(vm, child);
          if (item.name === 'otherRouter') {
            isOtherRouter = true;
          }
        }
      });
    }
  });
  let currentPathArr = [];
  if (name === 'viewer') {
    currentPathArr = [{
      title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'viewer')),
      path: '',
      name: 'viewer'
    }];
  } else if ((name.indexOf('_index') >= 0 || isOtherRouter) && name !== 'viewer') {
    currentPathArr = [{
        title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'viewer')),
        path: '/home',
        name: 'viewer'
      },
      {
        title: title,
        path: '',
        name: name
      }
    ];
  } else {
    let currentPathObj = vm.$store.state.app.routers.filter(item => {
      if (item.children.length <= 1) {
        return item.children[0].name === name;
      } else {
        let i = 0;
        let childArr = item.children;
        let len = childArr.length;
        while (i < len) {
          if (childArr[i].name === name) {
            return true;
          }
          i++;
        }
        return false;
      }
    })[0];
    if (currentPathObj.children.length <= 1 && currentPathObj.name === 'home') {
      currentPathArr = [{
        title: 'Main',
        path: '',
        name: 'viewer'
      }];
    } else if (currentPathObj.children.length <= 1 && currentPathObj.name !== 'home') {
      currentPathArr = [{
          title: 'Main',
          path: '/home',
          name: 'viewer'
        },
        {
          title: currentPathObj.title,
          path: '',
          name: name
        }
      ];
    } else {
      let childObj = currentPathObj.children.filter((child) => {
        return child.name === name;
      })[0];
      currentPathArr = [{
          title: 'Main',
          path: '/home',
          name: 'viewer'
        },
        {
          title: currentPathObj.title,
          path: '',
          name: currentPathObj.name
        },
        {
          title: childObj.title,
          path: currentPathObj.path + '/' + childObj.path,
          name: name
        }
      ];
    }
  }
  vm.$store.commit('setCurrentPath', currentPathArr);

  return currentPathArr;
};

util.toDefaultPage = function (routers, name, route, next) {
  let len = routers.length;
  let i = 0;
  let notHandle = true;
  while (i < len) {
    if (routers[i].name === name && routers[i].children && routers[i].redirect === undefined) {
      route.replace({
        name: routers[i].children[0].name
      });
      notHandle = false;
      next();
      break;
    }
    i++;
  }
  if (notHandle) {
    next();
  }
};

export default util;