const proj4 = require('proj4').default;

function CoordTransform(/*x, y*/)
{
	// No non-static members yet
}

// Static fields
CoordTransform.gWGS84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
CoordTransform.gHK1980 = "+proj=tmerc +lat_0=22.31213333333334 +lon_0=114.1785555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.24365,-1.15883,-1.09425 +units=m +no_defs";

CoordTransform['4326'] = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
CoordTransform['3857'] = "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs";
/// Thess ones should be better dynamically defined by fetching from the PostGIS database!
/// Dillema here. This class could be initiated several times, yet if calling the server everytime the class is up, not good
CoordTransform['2326'] = "+proj=tmerc +lat_0=22.31213333333334 +lon_0=114.1785555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.24365,-1.15883,-1.09425 +units=m +no_defs";
CoordTransform['3826'] = "+proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defss";
CoordTransform['4547'] = "+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs";
CoordTransform['4526'] = "+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=38500000 +y_0=0 +ellps=GRS80 +units=m +no_defs";

/*
 * Static functions
 */
// WGS 84 to HK Grid 1980
/// Obsolete, to be replaced EVERYWHERE...
CoordTransform.wgs2hk = function(lng, lat, h) {
	console.warn('CoordTransform.wgs2hk is deprecated. Use CoordTransform.translate instead');
	/* var result = proj4(CoordTransform.gWGS84, CoordTransform.gHK1980, [lng, lat]);
	if(h!=undefined)
		result.push(h);

	return result; */
	
	var originArr = [lng, lat];
	if(h!=undefined)
		originArr.push(h);
	var result = proj4(CoordTransform.gWGS84, CoordTransform.gHK1980, originArr);
	
	if(h!=undefined)
		result[2] = h;
	
	return result;
}

CoordTransform.hk2wgs = function(x, y, h) {
	console.warn('CoordTransform.wgs2hk is deprecated. Use CoordTransform.translate instead');
	var result = proj4(CoordTransform.gHK1980, CoordTransform.gWGS84, [x, y]);
	if(h!=undefined)
		result.push(h);
		
	return result;
}

/*
{
	fromEPSG: '4326',
	toEPSG: '2326',

	// Or directly use fromProj4 and toProj4 strings

	x: 114.14,
	y: 22.28,
	h: 30
}
 */
CoordTransform.translate = function(options) {
	if(!options.fromEPSG && !CoordTransform[options.fromEPSG]) {
		options.fromEPSG = '4326';	// Default set as WGS84 if not provided
	}
	if(!options.toEPSG && !CoordTransform[options.toEPSG]) {
		options.toEPSG = '4326';	// Default set as WGS84 if not provided
	}
	if(!options.x || !options.y) {
		return;
	}
	
	var fromString = options.fromProj4 ? options.fromProj4 : CoordTransform[options.fromEPSG];
	var toString = options.toProj4 ? options.toProj4 : CoordTransform[options.toEPSG];
	
	var result = proj4(fromString, toString, [Number(options.x), Number(options.y)]);
	if(options.h!=undefined)
		result.push(options.h);
		
	return result;
}

/*
{
	EPSG: '4326',	// Not implemented
	x: 114.14,
	y: 22.28,
	isProj4: true
}

return {
	utmName: 'UTM Zone 50S',
	proj4: '+proj=utm +zone=50 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs'
}
*/
CoordTransform.getUTM = function(point) {
	var longitudeZone = Math.ceil((point.x+180)/6);
	var isSouth=false;
	if(point.y<0)
		isSouth = true;
	
	var utmName = `UTM Zone ${longitudeZone}${isSouth ? `S` : `N`}`;
	
	var returnObj = {
		utmName: utmName,
		utmZoneNo: longitudeZone,
		utmNS: isSouth ? 'S' : 'N'
	};
	
	if(point.isProj4) {
		var proj4String = `+proj=utm +zone=${longitudeZone}${isSouth ? ` +south` : ``} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`;
		returnObj.proj4 = proj4String;
	}
	
	return returnObj;
}

/*
{
	EPSG: '4326',
	x: 114.14,
	y: 22.28,
	h: 30
}
 */
CoordTransform.toUTM = function(options) {
	if(!Array.isArray(options)) {	// If single point
		var EPSG = options.EPSG;
		if(!EPSG)
			EPSG = '4326';
		
		var wgspt = [options.x, options.y, options.h];
		if(EPSG != '4326') {
			wgspt = this.translate({
				fromEPSG: EPSG,
				toEPSG: '4326',
				x: options.x,
				y: options.y,
			});
		}
		
		// Get which UTM it is in
		var utmInfo = this.getUTM({
			x: wgspt[0],
			y: wgspt[1],
			isProj4: true
		});
		
		var utmpt = proj4(CoordTransform['4326'], utmInfo.proj4, [wgspt[0], wgspt[1]]);
		utmpt[0] = Number(utmpt[0].toFixed(3));
		utmpt[1] = Number(utmpt[1].toFixed(3));
		if(options.h!=undefined) 
			utmpt.push(options.h);
		
		return {
			point: utmpt,
			projName: utmInfo.utmName,
			utmZoneNo: utmInfo.utmZoneNo,
			utmNS: utmInfo.utmNS
		};
	}
	else {	// An array of points, means to ask for batch conversion
		if(options.length==0)
			return;
		
		// The very first thing to do: check the utm zone of the centroid
		// So that all the points are converted into the same coordinate system
		var ptx=0, pty=0, EPSG = options[0].EPSG;
		for(var i=0; i<options.length; i++) {
			var option = options[i];
			ptx += option.x;
			pty += option.y;
		}
		ptx /= options.length;
		pty /= options.length;
		
		if(!EPSG)
			EPSG = '4326';
		
		var wgspt = [ptx, pty];
		if(EPSG != '4326') {
			wgspt = this.translate({
				fromEPSG: EPSG,
				toEPSG: '4326',
				x: ptx,
				y: pty,
			});
		}
		
		var projInfo = this.getUTM({
			x: wgspt[0],
			y: wgspt[1],
			isProj4: true
		});
		
		var tempIndex = Date.now();
		this[tempIndex] = projInfo.proj4;
		
		// Convert all points to this coordinate system
		var resultset = [];
		for(var i=0; i<options.length; i++) {
			resultset.push(this.translate({
				fromEPSG: EPSG,
				toEPSG: tempIndex,
				x: options[i].x,
				y: options[i].y,
				h: options[i].h
			}));
		}
		this[tempIndex] = undefined;
		
		return {
			point: resultset,
			projName: projInfo.utmName,
			utmZoneNo: projInfo.utmZoneNo,
			utmNS: projInfo.utmNS
		};
	}
}

/*
{
	utmZoneNo: '49',
	utmNS: 'N',
	points: [{
		toEPSG: '4326',
		x: 114.14,
		y: 22.28,
		h: 30
	}]
}
 */
/// Really?
CoordTransform.fromUTM = function(options) {
	function getUTMProj4 (utmZoneNo, utmNS) {
		return `+proj=utm +zone=${utmZoneNo}${utmNS=='S' ? ` +south` : ``} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`;
	}
	
	var randomIndex = Date.now();
	this[randomIndex] = getUTMProj4(options.utmZoneNo, options.utmNS);
	
	var points = options.points;
	var translated;
	if(!Array.isArray(points)) {
		translated = this.translate({
			fromEPSG: randomIndex,
			toEPSG: points.toEPSG,
			x: points.x,
			y: points.y,
			h: points.h
		});
	}
	else {
		translated = [];
		for(var i=0; i<points.length; i++) {
			translated.push(this.translate({
				fromEPSG: randomIndex,
				toEPSG: points[i].toEPSG,
				x: points[i].x,
				y: points[i].y,
				h: points[i].h
			}));
		}
	}
	
	this.randomIndex = undefined;
	return translated;
}

// Register a new epsg, since we do not want to directly include all possible coordinate systems
/// No need to have any functions for this. Directly do the following:
/// CoordTransform['3333'] = "+proj=tmerc ...(the entire proj4 string)";

// module.exports = CoordTransform;
export default CoordTransform;
