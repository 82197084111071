import Vue from 'vue';
import ViewUI from 'view-design';
import Util from '../libs/util';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';
/// How useful would this be
import VueSanitize from 'vue-sanitize';
import {
  routers,
  otherRouter,
  appRouter
} from './router';

Vue.use(VueRouter);

// HTML sanitizer against XSS attack from rich-text editor
Vue.use(VueSanitize, {
  allowedTags: ['a', 'img', 'iframe', 'p', 'table', 'tbody', 'tr', 'td'],
  allowedAttributes: {
    'a': ['href'],
    'img': ['src', 'width', 'height'],
    'table': ['style', 'border'],
    'td': ['style'],
  },
  allowedSchemesByTag: {
    'img': ['data', 'http', 'https']
  }
});

const RouterConfig = {
  // mode: 'history',
  routes: routers
};

export const router = new VueRouter(RouterConfig);

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  // The setting of whether using secondary url like *.foo.bar, sometimes not applicable, especially for IPs
  window.isSubDomainSupport = true;

  window.root_platform = true;
  var subdomain = "";
  var hostitems = window.location.host.split('.');
  var isLocalhost = window.location.host.indexOf('localhost') > -1;

  if (window.isSubDomainSupport) {
    if (isLocalhost && hostitems.length > 1) {
      subdomain = `${hostitems[0]}.`;
      window.root_platform = false;
      window.subdomainName = hostitems[0];
    } else if (!isLocalhost && hostitems.length > 2) {
      subdomain = `${hostitems[0]}.`;
      window.root_platform = false;
      window.subdomainName = hostitems[0];
    }
  }
  else {
    window.subdomainName = '';      // Or hardcode this to a specific hub name
  }

  window.isProduction = true;       // Mainly for 3D rendering settings for whether in develop mode
                                    // Set to true when about to deploy. Otherwise false if any shaders to debug

  // window.baseHost = `https://${subdomain}vr3d.cloud`;
  // window.baseHost = 'https://cepsua.raspect.ai';
  //window.baseHost = 'http://sdms-staging.ap-east-1.elasticbeanstalk.com';
  window.baseHost = window.location.origin;
  //window.baseHost = 'http://127.0.0.1:8080';
  let baseAPI = '/sdms';
  window.Baseurl = `${window.baseHost}${baseAPI}`;

  window.iserverUrl = 'https://vr3d.cloud';


  window.siteName = '';

  if (to.path !== from.path) {
    ViewUI.LoadingBar.start();
  }
  Util.title(to.meta.title);
 /// Global parameter recycling, for each routes. Destroy and remove global params that are no longer necessary after redirecting to other pages
  switch (from.name) {


    case 'viewer':

      if (window.map) {
        delete (window.map);
      }
      if (window.map2D) {
        delete (window.map2D);
      }

      if (window.mapOL) {
        window.mapOL.setTarget(null);
        delete (window.mapOL);
      }
      if (window.viewer) {
        window.viewer.destroy();
        delete (window.viewer);
      }
      break;

  }
  console.log("Finish From clean up")

  /// May need modification, maybe not equal to any project templates
  if (to.name != 'workspaceviewer') {
    window.integratedObj = {};

    if (window.viewer) {
      try {
        window.viewer.entities.removeAll();
        window.viewer.scene.layers.removeAll();
        window.viewer.scene.primitives.removeAll();
        window.viewer.destroy();

        if (window.viewer_split) {
          window.viewer_split.entities.removeAll();
          window.viewer_split.scene.layers.removeAll();
          window.viewer_split.scene.primitives.removeAll();
          window.viewer_split.destroy();
        }
      } catch (e) {
        console.log(e);
      }
      window.viewer = undefined;
      window.viewer_split = undefined;

      if (window.integratedObj) {
        window.integratedObj.animationLayerControl = undefined;
        delete (window.integratedObj.animationLayerControl);
      }

      if (window.control3D) {
        window.control3D.movementMode.deRegister();
        delete (window.control3D);
      }
    }
  }

  console.log("Finish to clean up")

  let demoRouteList = ['crender', 'hcrender', 'cdashboard'];

  if (!Cookies.get("sdms_token") && (to.name !== 'login' && to.name !== 'apploading' ) && demoRouteList.indexOf(to.name) == -1) {

    let sv, ws;
    let templateList = ['viewer','lite'];

    if (templateList.indexOf(to.name) != -1 && to.query && to.query.ws) {
      ws = to.query.ws;

      const curRouterObj = Util.getRouterObjByName([otherRouter, ...appRouter], to.name);
      Util.toDefaultPage([...routers], to.name, router, next);
    } else {
      if (to.query && to.query.sv)
        sv = to.query.sv;

      let nextObj = {
        name: 'login'
      };
      if (sv)
        nextObj.query = {
          sv: sv
        };

      next(nextObj);
    }

  } else if (Cookies.get("sdms_token") && to.name === 'login') {
      console.log('Got overriden to workspace lite')
      next(
          {
            name: 'workspacelite',
          }
      )
  } else {
    const curRouterObj = Util.getRouterObjByName([otherRouter, ...appRouter], to.name);
    Util.toDefaultPage([...routers], to.name, router, next);
  }
});

router.afterEach((to, from) => {
  if (to.path !== from.path) {
    ViewUI.LoadingBar.finish();
    window.scrollTo(0, 0);
  }
});
