<template>
  <router-view></router-view>
</template>
<script>
export default {
  data () {
    return {};
  },
  mounted () {
    this.init();
  },
  beforeDestroy () { },
  methods: {
    init () {
      let themeLink = document.querySelector("body");
      if (!window.localStorage.theme || window.localStorage.theme == null)
        window.localStorage.theme = "sdmslight";

      themeLink.setAttribute("class", window.localStorage.theme);
    },
  },
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
}
</style>
