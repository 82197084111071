import appwrapper from '../views/page/appwrapper.vue';


export const loginRouter = {
  path: '/login',
  name: 'login',
  meta: {
    title: 'Login'
  },
  component: resolve => {
    require(['../views/page/signin/login.vue'], resolve);
  }
};

export const otherRouter = [
  {
    path: '/lite',
    name: 'lite',
    component: (resolve) => require(['../views/viewer/viewer.vue'], resolve)
  }
];

export const appRouter = [{
    path: '/',
    name: 'homeindex',
    redirect: "/login"
  },
  {
    path: '/loading',
    name: 'apploading',
    title: 'Loading...',
    component: (resolve) => require(['../views/page/signin/apploading.vue'], resolve)
  },

  {
    path: '/resources',
    component: appwrapper,
    children: [{
      path: '',
      components: {
        default: resolve => {
          require(['../views/page/resourcemenu.vue'], resolve);
        }
      },
      children: [
        {
          path: 'index',
          name: 'allresources',
          component: (resolve) => require(['../views/page/resources.vue'], resolve)
        }
      ]
    }]
  },
  {
    path: '/sdms',
    component: appwrapper,
    children: [{
      path: 'i',
      components: {
        default: resolve => {
          require(['../views/page/projects/projectmenu.vue'], resolve);
        }
      },
      children: [
        {
          path: 'lite',
          name: 'workspacelite',
          component: (resolve) => require(['../views/viewer/viewer.vue'], resolve)
        }
      ]
    }]
  }
];

export const routers = [
  loginRouter,
  ...otherRouter,
  ...appRouter
];