import Vue from 'vue';
import ViewUI from 'view-design';
import VcRibbon from '/public/vcribbon/vc-ribbon.min.js'
import {
  router
} from './router/index';
import App from './app.vue';
import VueI18n from 'vue-i18n';
import 'v-contextmenu/dist/index.css';

import '/public/vcribbon/vc-ribbon.min.css';

import Locales from './locale';
import enLocale from 'view-design/src/locale/lang/en-US';
import zhCNLocale from 'view-design/src/locale/lang/zh-CN';
import zhTWLocale from 'view-design/src/locale/lang/zh-TW';
import _ from "lodash";

import '@/assets/styles/theme/iviewtheme.less';

window._ = _;

Vue.use(VueI18n);
Vue.use(ViewUI);
Vue.use(VcRibbon);
Vue.locale = () => {};

const navLang = navigator.language;
const localLang = (navLang === 'zh-CN' || navLang === 'en-US') ? navLang : false;
const lang = window.localStorage.getItem('language') || localLang || 'en-US';

Vue.config.lang = lang;
window.localStorage.language = lang;

const locales = Locales;

const messages = {
  'en-US': Object.assign(locales['en-US'], enLocale),
  'zh-CN': Object.assign(locales['zh-CN'], zhCNLocale),
  'zh-TW': Object.assign(locales['zh-TW'], zhTWLocale)
};

const i18n = new VueI18n({
	locale: lang,
	fallbackLocale: 'en-US',
	messages: messages
});

Vue.prototype._i18n = i18n;
window.URAVue = new Vue({
  i18n,
  el: '#app',
  transfer: true,
  router: router,
  render: h => h(App),
  data: {
    currentPageName: '',
    aiTaskNum: 0,
  },
  mounted() {
    if (document.addEventListener) {
      document.addEventListener('contextmenu', function (e) {
      }, false);
    }
    this.currentPageName = this.$route.name;
  },
  created() {}
});
