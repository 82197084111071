export default {
  'en-US': {
    x_data_loading: 'Data loading...',
    x_my_overview: 'My Overview',
    x_all_member: 'All Members',
    x_overview_project: 'Project',
    x_overview_resource_storage: 'Resource Storage',
    x_overview_workspace: 'Workspace',
    x_overview_user: 'User',
    x_overview_resourve: 'Resource',
    x_overview_vehicle_management: 'Vehicle',
    x_management: 'Management',
    x_overview_today: 'Daily',
    x_overview_weekly: 'Weekly',
    x_overview_monthly: 'Monthly',
    x_overview_sixty_days: '60 days',
    x_overview_project_top_ten: 'Project visit (Top 10)',
    x_overview_workspace_top_ten: 'Workspace visit (Top 10)',
    x_overview_total_visit: 'Total visit',
    x_viewer: 'Viewer',
    x_general: 'General',
    x_mode: 'Mode',
    x_of: ' ',
    x_or: ' or ',
    x_and_bing: ' and ',
    x_add_to: 'Add to ',
    x_and: 'and',
    x_remove: 'Remove',
    // weather
    x_uvindex: 'Uv Index',

    x_project: 'Project',
    x_workspace: 'Workspace',
    x_assets: 'Assets',
    x_sensors: 'Sensors',
    x_dms_admin: 'DMS Admin',
    x_streaming_video: 'Live video',
    x_pms: 'Photo management',
    x_pms_download: 'Download',
    x_pms_compare: 'Compare',
    x_pms_create_new: 'Create new album',
    x_layer: 'Layers',
    x_3d_viewer: '3D Viewer',
    x_pointcloud_viewer: 'Point Cloud Viewer',
    site_title: 'VR3D CIM Platform',
    x_username: 'Username',
    x_username_or_email: 'Email Address',
    x_password: 'Password',
    x_confirm_password: 'Confirm password',
    x_captcha_text: 'Captcha Text',
    x_login: 'Login',
    x_forget_pwd: 'Forget password?',
    x_comfirm: 'Confirm',
    x_username_empty_err: 'Username can not be empty',
    x_password_empty_err: 'Password can not be empty',
    x_comfirm_password_empty_err: 'Comfirm password can not be empty',
    x_password_err: 'The two passwords are inconsistent',
    x_user_management: 'User Management',
    x_user_manual:'User Manual',
    x_change_password: 'Change Password',
    x_current_password: 'Current Password',
    x_set_password: 'Set Password',
    x_remove_password: 'Remove Password',
    x_new_password: 'New Password',
    x_new_password_dup: 'Confirm New Password',
    x_logout: 'Logout',
    x_search: 'Search',
    x_reset: 'Reset',
    x_site_mission_corsys: 'Coordinate System',
    x_default_coord_sys: 'Default coordinate system',
    x_coord_sys_placeholder: 'Coordinate system name or EPSG code',
    x_coord_sys_default_wgs84: 'Default is WGS 84 (4326)',
    x_measurement: 'Measurement',
    x_ms_height: 'Height',
    x_ms_coordinate: 'Coordinate',
    x_ms_volume: 'Volume',
    x_ms_area_projected: 'Projected Area',
    x_ms_angle: 'Angle',

    x_information: 'Information',
    x_style: 'Style',
    x_style_lowercase: 'style',
    x_attribute: "Attribute",
    x_2d_border: 'Outline',
    x_2d_cap: 'Cap',
    x_2d_dash: 'Dash',
    x_2d_width: 'Width',
    x_2d_font: 'Font',
    x_2d_size: 'Size',
    x_3d_heading: 'Heading',

    x_3d_tidal_analysis: 'Tidal Analysis',
    x_3d_line_of_sight: 'Line of Sight',
    x_3d_visual_analysis: 'Viewshed',
    x_3d_site_formation: 'Site Formation',
    x_3d_cut_section: 'Cut Section',
    x_3d_skyline_analysis: 'Skyline',
    x_3d_shadow_analysis: 'Shadow Analysis',
    x_3d_skyview: 'Skyview',
    x_3d_skyview_graph: 'Skyview Graph',
    x_3d_skyview_factor: 'Skyview Factor',
    x_range: 'Range',
    x_pointcloud_is_3dtiles: 'Publish for 3D viewer',
    x_pointcloud_is_potree: 'Publish for point cloud viewer',
    x_at_least_one_chose: 'At least one of the following should be chosen',

    x_pwd_very_weak: 'Very weak',
    x_pwd_weak: 'Weak',
    x_pwd_moderate: 'Moderate',
    x_pwd_strong: 'Strong',
    x_pwd_weak_no_submit: 'Password is too weak. Please provide a stronger password',

    x_3d_fly_through: 'Fly Through',
    x_fly_path_add: 'Add Fly Through',
    x_back_to_fly_path: 'Back to Fly Through Management',

    x_3d_edit_arrow: '3D Arrow',
    x_3d_edit_3d_polyline: '3D Polyline',
    x_3d_edit_3d_box: 'Prism',
    x_3d_edit_cylinder: 'Cylinder',
    x_3d_edit_rectangle: 'Cuboid',
    x_3d_edit_annotation: 'Annotation',
    x_3d_edit_redline: 'Redline Comment',
    x_3d_edit_file: 'FILE',
    x_3d_model: 'Models',
    x_3d_edit_pipe: 'Pipeline',
    x_3d_edit_site: 'Flatten Site',
    x_edit_upload_file: 'File Upload',
    x_3d_edit_rectangle_2d: 'Rectangle',
    x_3d_edit_ellipse_2d: 'Ellipse',
    x_close: 'Close',
    x_signup: 'Sign up',
    x_signup_name: 'Name',
    x_signup_email: 'Email',

    x_create: 'Create',
    x_submit: 'Submit',
    x_empty_err: 'This field can not be empty',
    x_illegal_err: 'This field can not input illegal character',
    x_edit: 'Edit',
    x_edit_api: 'Edit API',
    x_delete: 'Delete',
    x_share: 'Share',
    x_role: 'Role',
    x_teams: 'Teams',
    x_register_success: 'Sign up successfully, Please login!',
    x_email_invlidate: 'Incorrect email format',
    x_internal_server_err: 'Server Error!',
    x_annotations: 'Annotations',
    x_save: 'Save',
    x_nav_2D_view: '2D View',
    x_nav_position: 'Position',
    x_nav_fly_to: 'Fly to',
    x_nav_lock_position: 'Lock Position',
    x_nav_zoom_to_bound: 'Zoom to Bound',
    x_nav_view_mode: 'Viewing Mode',
    x_nav_default: 'Mouse Control',
    x_nav_first_person: 'Keyboard Control',
    x_site_management: 'Project Management',
    x_nav_mission: 'Mission',
    x_sample: 'Sample',
    x_sample_trajectory: 'Click to download a sample trajectory file',

    x_saving: 'Saving...',
    x_processing: 'Processing...',
    x_ok: 'OK',
    x_cancel: 'Cancel',
    x_success: 'Success',
    x_site_title: 'Title',
    x_site_guest_accessible: 'Guest Accessible',
    x_site_missions: 'Missions',
    x_add_mission: 'Add mission',
    x_split_view: 'Reference View',
    x_split_mode: 'Split View',
    x_curtain_mode: 'Curtain View',

    x_clear_operation: 'Clear',
    x_lang: 'Language',
    x_basemap: 'Base map',
    x_3d_baselayer: '3D Base Layer',
    x_render_quality: 'Render quality',
    x_render_effect: 'Effects',
    x_render_quality_low: 'Low',
    x_render_quality_normal: 'Normal',
    x_render_quality_high: 'High',
    x_sun_shadow: 'Sun and Shadow',
    x_blooming: 'Blooming',
    x_depth_of_field: 'Depth of Field',
    x_3d_terrain_flattening: 'Terrain Flattening',
    x_3d_clip_plane: 'Clipping Plane',
    x_3d_clip_box: 'Clipping Box',
    // x_3d_clip_plane_horizontal: 'Horizontal Clipping',
    // x_3d_clip_plane_vertical: 'Verticle Clipping',
    x_2d_view: '2D/3D view mode',
    x_true_north: 'True North',
    x_shadow_height: 'Height Above',
    x_upload_title: 'Model Upload',
    x_upload_drag_note: 'Click or drag to upload',
    x_upload_note: 'Click or drag to upload zipped obj model',
    x_bookmark: 'Bookmark',
    x_bookmark_no_bookmark: 'No bookmark',

    x_bookmark_name: 'Bookmark Name',
    x_data_upload: 'Data Upload',
    x_select_upload_folder: 'Select Upload Folder',
    x_select_data_folder: 'Select Data Folder',
    x_folder_no_type_data: 'Select folder does not contain selected type of data',
    x_cal_pub_size: 'Calculating size required',
    x_pub_size_req: 'Size required',
    x_pub_size_total: 'Available resource size',
    x_remain_pub_size: 'Remaining resource size',
    x_size_resource: 'Size',

    x_upload: 'Upload',
    x_upload_lng: 'Longitude',
    x_upload_lat: 'Latitude',
    x_upload_height: 'Altitude',
    x_model_editing: 'Model Editing',
    x_move: 'Move',
    x_rotate: 'Rotate',
    x_resource_search: 'Resource Search',

    x_addlayer: 'Add New Layer',
    x_delete_layer: 'Delete Layer(s)',
    x_navigation: 'Navigation',
    x_analytics: 'Analytics',
    x_full_screen: 'Full Screen',
    x_fly_home: 'Fly to home',
    x_set_project_position: 'Save Project Initial Position',
    x_screen_cap: 'Screen Capture',
    x_screen_rec: 'Record Scene',
    x_rec_stop: 'Stop',
    x_nodata: 'No Data',
    x_none: 'None',
    x_normal: 'normal',
    x_loading: 'Loading...',

    x_pms_title: 'Title',
    x_pms_tags: 'Tags',
    x_pms_start_date: 'Start date',
    x_pms_end_date: 'End date',
    x_pms_define_region: 'Define Filter Region',
    x_pms_filter: 'Filter',
    x_pms_search_region: 'Filter Region',
    x_pms_selected: 'Selected',
    x_pms_selectall: 'Select All',
    x_pms_unselectall: 'Unselect All',
    x_pms_is_trajectory: 'Generate Trajectory',
    x_pms_layers: 'Associative layers',
    x_pms_layers_select: 'Please select an associated layer',
    x_pms_view_map: 'Map View',
    x_pms_view_grid: 'Grid View',
    x_pms_view_type: 'View Mode',
    // x_pointcloud_is_potree: 'Publish to Pointcloud Viewer',
    x_pano_is_face_blur: 'privacy blurring',
    x_eo_format: 'EO file format',
    x_spatial_offset: 'Spatial Offset',

    x_eo_loading: 'Reading image EO',
    x_img_info_table: 'Image metadata',
    x_upload_eo_csv: 'Upload EO CSV',
    x_download_eo: 'Download EO CSV',
    x_publish_resource: 'Publish Resource',
    x_eo_ph_name: 'Photo Name',
    x_easting: 'Easting',
    x_northing: 'Northing',
    x_omega: 'Omega',
    x_phi: 'Phi',
    x_kappa: 'Kappa',
    x_eo_img_total_count: 'images in the directory',
    x_eo_img_positioned: 'images are positioned',

    x_nav_enter_google_street: 'Enter Google StreetView',
    x_nav_google_transparency: 'StreetView Transparency',
    x_nav_back_3d: 'Back to 3D View',
    x_nav_streetview_not_found: 'Street View data not found for this location',

    x_ms_volume_base_type: 'Base type',
    x_ms_volume_base_low: 'Align with lowest vertex',
    x_ms_volume_base_high: 'Align with highest vertex',
    x_ms_volume_base_ave: 'Align with average plane',
    x_ms_volume_base_triang: 'Triangulation',
    x_ms_volume_base_man: 'Manual input',
    x_ms_volume_quick_measure: 'Quick measure',

    x_ms_volume_base_level: 'Base level (m)',
    x_ms_volume_calculate: 'Calculate Volume',
    x_ms_volume_bl: 'Base level',
    x_ms_sampling_interval: 'Sampling interval',
    x_ms_error_prop: 'Error estimation',
    x_ms_volume_cv: 'Cut Volume',
    x_ms_volume_fv: 'Fill Volume',
    x_ms_volume_tv: 'Total Volume',

    x_3d_terrain_excavation: 'Terrain Excavation',
    x_flood_level: 'Level (m)',
    x_flood_level_placeholder: 'Level of flood (m)',
    x_flood_define_flood_region: 'Define flood region',
    x_shadow_start_time: 'Start Time',
    x_shadow_end_time: 'End Time',
    x_shadow_animate: 'Animate',
    x_shadow_calculate_ratio: 'Calculate sunlight ratio',
    x_sun_exposure: 'Sunlight Exposure',

    x_stop_as_current_pos: 'Update as current view',
    x_fly_speed: 'Fly speed',
    x_duration: 'Duration',
    x_fly_save_video: 'Save as video after animation',
    x_fly_video_name: 'Video name',
    x_fly_add_stop: 'Add Stop',
    x_fly_animate: 'Animate & Play',

    x_drawing_area: 'Area',
    x_drawing_footprint: 'Footprint(m2)',
    x_drawing_color: 'Color',
    x_action: 'Action',

    x_sure_del_sketch: "Are you sure to delete?",
    x_edit_title: 'Edit title',
    x_sketch_add: 'Add sketch design',
    x_quit_sketch_design: 'Quit sketch design',
    x_export_sketch_design: 'Export design',
    x_floor_count: 'Floor Count',
    x_floor_height: 'Floor Height(m)',
    x_gfa_efficiency: 'Efficiency',
    x_gfa: 'GFA',

    x_project_folder_selection: 'Folder Selection',
    x_project_new_folder: 'New Folder',
    x_project_folder_name: 'Folder Name',
    x_project_upload_step1: 'Step 1: Select Remote Upload Folder',
    x_project_upload_step2: 'Step 2: Select Local File',
    x_category: 'Category',
    x_project_publish_resource_name: 'Resource Name',
    x_static_data: 'Static Data',
    x_dynamic_data: 'Dynamic Data',
    x_refresh_time: 'Refresh Time(min)',
    x_capture_date: 'Data Capture Date',
    x_project_publish_layerurl: 'Resource URL',
    x_project_publish_ogc_layername: 'Layer to include in the service',
    x_ogc_tile_matrix_set: 'Tile matrix set',
    x_enable_supermap_query: 'Enable query through SuperMap data service',
    x_supermap_query_url: 'Query data service URL',
    x_supermap_datasource: 'Datasource',
    x_dataset: 'Dataset',
    x_data_value: 'Data Value',
    x_url_inaccessible: 'Service url not accessible',
    x_url_format_invalid: 'Service response not recognized',
    x_upload_4d_script: 'Upload timeline script',
    x_read_4d_complete: 'Upload timeline script complete',
    x_sm_dataset_default_merge: 'No need to provide if the service is from merged dataset',
    x_upload_supermap_workspace: 'Upload SuperMap workspace file',

    x_project_msg_uploading: 'Uploading...',
    x_project_msg_add_layer_success: 'Add layer success',
    x_login_msg_login_incorrect: 'Username or password are not correct',
    x_index_msg_add_bookmark: 'Add bookmark successful.',
    x_index_msg_delete_bookmark: 'Delete bookmark success',
    x_index_msg_bookmark_share: 'Bookmark share URL copied to the clipboard.',

    x_index_msg_zip_file: 'Zipping files',
    x_index_msg_zip_photo: 'Zipping your photos',
    x_index_msg_to_enter_google: 'Please pick a point on the ground to enter the Google Street View.',
    x_index_msg_save_init_position: 'Saving project initial position',
    x_index_msg_save_init_position_success: 'Save project initial position success.',
    x_index_msg_generate_video: 'Generating video...',
    x_index_msg_model_share: 'Model share URL copied to the clipboard.',
    x_index_msg_model_upload: 'Model Uploading',
    x_index_msg_model_process: 'Processing Model',
    x_index_msg_model_success: 'Model loading success',
    x_terrain_transparency: 'Terrain transparency',

    x_upload_md5_cal: 'Reading file...',
    x_upload_md5_fail: 'Read file failed',
    x_upload_sm_no_scene: 'No 3D scene in the workspace',

    x_volume_management: 'Volume Management',
    x_vm_title: 'Stockpile Name',
    x_vm_base_height: 'Base Height (m)',
    x_vm_create_new: 'Create Volume Measurement',
    x_vm_to_draw_region: 'Left click to define the site region on 3D scene. Right click to finish',
    x_vm_no_result: 'No volume calculation result. Please calculate first.',
    x_vm_stockpile: 'Stockpile',
    x_vm_date: 'Last Calculate Date',
    x_vm_left_viewer: 'Left Viewer',
    x_vm_right_viewer: 'Right Viewer',
    x_vm_report_title: 'Volume Measurement Report',
    x_vm_usage: 'Draw the measurement region in the 3D viewer or upload a csv file of base surface',

    x_add_record_service_api_url: 'Reading Service API URL',

    x_vm_fly_to: 'Fly to',
    x_vm_visible: 'Toggle Visibility',
    x_vm_calculate: 'Calculate',
    x_vm_report: 'Show Report',

    x_vm_site_mode: 'Source file type',
    x_vm_region_ring: 'Polygon region',
    x_vm_spot_tin: 'Spot heights',

    x_pipe_edit_title: 'Edit 3D Pipe',
    x_pipe_edit_x: 'x(m)',
    x_pipe_edit_y: 'y(m)',
    x_pipe_edit_z: 'z(m)',
    x_pipe_edit_width: 'width(m)',

    x_console: 'System Management',
    x_dept_name: 'Group Name',
    x_add: 'Add ',
    x_update: 'Update',
    x_feature: 'Feature',

    x_poi: 'POIs',
    x_poi_name: 'POI Name',
    x_poi_add: 'Add POI',

    x_nav_navigate: 'Navigate',
    x_nav_view: 'View',
    x_nav_streetview: 'Street View',
    x_nav_routing: 'Routing',
    x_nav_selection: 'Selection',
    x_nav_select: 'Identify',
    x_nav_locate: 'Locate',
    x_nav_search_poi: 'POI Search',
    x_nav_search_buffer: 'Buffer Query',
    x_nav_search_bound: 'Bound Query',
    x_nav_generate_plan: 'Generate Plan View',
    x_nav_helper: 'Helper',
    x_nav_save_as_profile: 'Save as Home',
    x_nav_tool: 'Tools',
    x_nav_bim: 'BIM',
    x_nav_measure: 'Measure',
    x_nav_analysis: 'Analysis',
    x_nav_drawings: 'Drawings',
    x_layer_panel: 'Layer Panel',
    x_bookmark_panel: 'Bookmark Panel',
    x_volume_panel: 'Volume Panel',
    x_model_panel: 'Model Panel',
    x_drawing_panel: 'Drawing Panel',
    x_pms_panel: 'PMS',
    x_nav_setting: 'Setting',
    x_nav_screen: 'Screen',
    x_compress_screen: 'Compress',
    x_nav_transparency: 'Transparency',
    x_timeline_play_4d: 'Play',
    x_timeline_pause_4d: 'Pause',
    x_timeline_loading: 'Loading timeline graph',
    x_timeline_no: 'No timeline found for current project layers',
    x_pt_styling: 'Point cloud styling',
    x_pt_point_size: 'Point size',
    x_pt_class_filter: 'Filter by classification',

    x_query_buffer_define_size: 'Define Buffer Radius',

    x_media_info: 'Media Information',
    x_properties: 'Properties',
    x_layer_setting: 'Layer Setting',
    x_zoom_to: 'Zoom To',
    x_nav_review: 'Review',
    x_nav_output: 'Output',
    x_nav_effects: 'Effects',
    x_nav_animation: 'Animation',
    x_add_in_module: 'Addon Modules',
    x_nav_2d_drawings: '2D Drawing',
    x_nav_3d_drawings: '3D Drawing',
    x_nav_3d_particle: 'Particle Effect',
    x_nav_3d_particle_fire: 'Fire',

    x_nav_terrain_analysis: 'Terrain Analysis',
    x_nav_line_of_site: 'Visual Analysis',
    x_nav_sectional_analysis: 'Sectional Analysis',
    x_nav_shadow_analysis: 'Shadow',

    x_batch_delete: 'Batch Delete',
    x_selected_resource: 'selected resource',
    x_signup_repwd: 'Re Enter Password',
    x_signup_repwd_error: 'ReEnter Password is not same as password',
    x_signup_phone_error: 'Phone format error',
    x_status: 'Status',
    x_user_disable: 'Disabled',
    x_user_normal: 'Normal',
    x_create_date: 'Create Date',
    x_role_name: 'Role Name',
    x_remark: 'Remark',
    x_role_access: 'Access',
    x_layer_type: 'Layer Type',
    x_service_publish: 'Service Publish',
    x_data_publish: 'Data Publish',
    x_clone_layer_publish: 'Clone Layer',
    x_title: 'Title',
    x_name: 'Name',
    x_date: 'Date',
    x_owner: 'Owner',
    x_desc: 'Description',
    x_type: 'Type',
    x_parent_dept: 'Parent',
    x_comfirm_deletion: "Are you sure to delete{0} {1}",
    x_user: 'User',
    x_existing_layer: 'Existing Layer',

    x_init_gis_publish: 'Create GIS Feature Resource',
    x_init_gis_tip: '2/3D Point/Line String/Polygon',
    x_geometry_type: 'Geometry Type',
    x_shp_point_2d: 'Point',
    x_shp_linestring_2d: 'Line String',
    x_shp_polygono_2d: 'Polygon',
    x_shp_circle_2d: 'Circle',
    x_shp_point_3d: '3D Point',
    x_shp_linestring_3d: '3D Line String',
    x_shp_polygon_3d: '3D Polygon',

    x_init_iot_publish: 'Create IoT Device Resource',
    x_init_ai_photogram: 'AI Photogrammetry',

    x_image_loading: 'Loading image',
    x_viewer_loading: 'Loading 3D Viewer',

    x_share_bkmk_copied: 'Bookmark share URL copied to the clipboard',
    x_home_success: 'Home set successfully',
    x_kml_invalid: 'Please upload a valid kml file',
    x_csv_invalid: 'Please upload a valid csv file',
    x_no_delete_root_folder: 'Root folder cannot be deleted',

    x_viewport: 'Viewport',
    x_viewport_category: 'Viewport Category',
    x_viewport_mgmt: 'Viewport management',
    x_viewport_goto: 'Go to viewport',
    x_viewport_detailed_info: 'Viewport information',
    x_viewport_info: 'Info',
    x_alert: 'Alert',
    x_alert_message: 'Receive a warning message',
    x_alert_info: 'Alert Info',
    x_viewport_scheduled: 'Scheduled',
    x_viewport_completed: 'Completed',
    x_viewport_category_add: 'Add Viewport Category',
    x_viewport_add: 'Add Viewport',
    x_viewport_category_name: 'Viewport Category Name',
    x_viewport_state: 'State',
    x_viewport_play_ctrl: 'Play Control',
    x_viewport_comment_pane: 'Comments',

    x_generate_share_link: 'Generating share link',
    x_back: 'Back',
    x_share_link: 'Share Link',
    x_viewport_type: 'Viewport Type',
    x_viewport_animated: 'Record',
    x_viewport_recording: 'The viewport is being recorded. Press \'Stop\' button on the right to finish',
    x_copy_to_clipboard: 'Copy to clipboard',

    x_home_management: 'Management',
    x_home_query: 'Query',
    x_temporary_layer: 'Temporary Layer',
    x_upload_obj: 'Upload OBJ',
    x_upload_kml: 'Upload KML',
    x_upload_geojson: 'Upload Geojson',
    x_upload_jpg: 'Upload JPG',
    x_upload_gltf: 'Upload GLTF',
    x_upload_csv_point: 'Upload CSV-Point',
    x_upload_csv_path: 'Upload CSV-Path',
    x_upload_bim_osgb: 'Upload BIM (osgb)',
    x_upload_batch: 'Upload Batch',
    x_measurement_point: 'Point',
    x_ms_distance: 'Distance',
    x_ms_perimeter: 'Perimeter',
    x_free_polyline: 'Length',
    x_dvh: 'DVH',
    x_vertical_ground: 'Height difference',
    x_distance_xyz: 'Distance XY&Z',
    x_perp_reference_line: 'Perpendicular distance to reference line',
    x_arc_3_digit: 'Arcs by 3 digits',
    x_measurement_area: 'Area',
    x_free_area: 'Free Area',
    x_vert_rect_diagonal: 'Vertical Rectangular Area Diagonal',
    x_vert_rect_3pts: 'Vertical Rectangular 3pts',
    x_vert_rect_intersect: 'Vertical Rectangular with intersection',
    x_horiz_rect: 'Horizontal rectangular area',
    x_horiz_sqrt_area_ground: 'Horizontal squared area from ground',
    x_free_area_ground: 'Projected area',
    x_horiz_circle_ctr_radius: 'Horizontal Circle by center and radius',
    x_horiz_circle_3d: 'Horizontal Circle by 3d points',
    x_measurement_volume: 'Volume',
    x_cut_fill: 'Cut & Fill',
    x_export: 'Export',
    x_export_shp: 'Export as Shapefile',
    x_export_kml: 'Export as KML',
    x_export_csv: 'Export as CSV',

    x_icon: 'Icon',
    x_design_view: 'Design&Review',
    x_anno_icon: 'ICON',
    x_anno_tag: 'TAG',
    x_nav_text: 'TEXT',
    x_anno_site_formation: 'Formation',
    x_nav_water: 'Water',
    x_drawing_circle: 'Circle',
    x_drawing_polyline: 'PolyLine',
    x_drawing_symbol: 'Symbol',
    x_drawing_arrow: 'Arrow',
    x_drawing_blank_indicator: 'Blank Indicator',
    x_drawing_text_indicator: 'Text Indicator',
    x_drawing_text: 'Text',
    x_drawing_list: 'Draw List',
    x_list: 'List',
    x_new_draw: 'New Draw',
    x_drawings: 'Draw',
    x_design_management: 'Management',
    x_collaboration: 'Collaboration',
    x_new_viewport: 'New Viewport',
    // x_new_viewport_group: 'New Viewport Group',
    // x_viewport_group: 'Viewport Group',
    x_new_viewport_group: 'New 4D tour',
    x_viewport_group: '4D tour',
    x_new_viewport_category: 'New Category',
    x_new_status: 'New Status',
    x_tracking: 'Tracking',
    x_table_of_content: 'Table of content',
    x_select_date: 'Select Date',

    x_resource_mgmt: 'Resources Management',
    x_security_mgmt: 'Security Management',
    x_use_statistics: 'Usage Statistics',
    x_user_pref: 'User Preferences',
    x_civil_tools: 'Civil Tools',
    x_planning: 'Planning',
    x_planning_design: 'Planning&Design',
    x_sketch_design: 'Sketch Design',
    x_design_scheme: 'Design Scheme',
    x_rdms_panel: 'RDMS',
    x_3dms_panel: '3DMS',
    x_mobile_app: ' Mobile',
    x_fms_panel: 'FMS',
    x_novade: 'Novade',
    x_bim_plus: '3DMS+',
    x_timeline_panel: 'Timeline',
    x_timeline_4d: 'Play 4D',
    x_bim360: 'BIM360',
    x_mms_panel: 'MMS',
    x_orbit: 'Orbit',

    x_stat_daily_view: 'Daily view statistics on',
    x_stat_proj_view_title: 'Project Views',
    x_stat_wks_view_real_title: 'Workspace Views by Named Users',
    x_stat_wks_view_anon_title: 'Workspace Views by Anonymous Users',
    x_stat_views_time: 'views',
    x_stat_views_time_real: 'views by named user',
    x_stat_views_time_anon: 'views by anonymous user',

    x_processing_tasks: 'Tasks',
    x_msg_list: 'Messages',
    x_menu_my_catalog: 'My Catalog',
    x_menu_storage: 'Storage',
    x_menu_resources: 'Resources',
    x_menu_workspaces: 'Workspaces',
    x_menu_viewports: 'Viewports',
    x_menu_company_catalog: 'Company Catalog',
    x_menu_users_teams: 'Users & Teams',
    x_menu_account: 'Account',
    x_menu_my_profile: 'My Profile',
    x_menu_company_profile: 'Company Profile',
    x_menu_plan_profile: 'Plan Profile',
    x_menu_statistics_dashboard: 'Statistics Dashboard',
    x_menu_more: 'More',
    x_menu_contact: 'Contact',
    x_menu_pricing: 'Pricing',
    x_menu_help_center: 'Help Center',
    x_home: 'Home',
    x_address: 'Address',
    x_website: 'Website',
    x_phone: 'Phone',
    x_mobile: 'Mobile',
    x_fax: 'Fax',
    x_setup: 'Setup',
    x_theme_dark_pro: "Dark Pro",
    x_theme_dark_blue: "294C",
    x_theme_dark: "Dark",
    x_theme_light: "Light",
    x_theme: 'Theme',
    x_my_preferences: 'My Preferences',
    x_company_preferences: 'Company Preferences',
    x_data_processing: 'Data Processing',
    x_refresh_data: 'Refresh Data',
    x_refresh_status: 'Refresh Status',
    x_change_profile_img: 'Change Profile Image',
    x_change_company_logo: 'Change Company Logo',
    x_create_new_user: 'Create New User',
    x_create_new_team: 'Create New Team',
    x_create_new_project: 'Create New Project',
    x_create_new_workspace: 'Create New Workspace',
    x_create_new_resource: 'Create New Resource',
    x_resource_index_map: 'Resource Indexing Map',
    x_signup_invite_code: 'Invite Code (Optional)',
    x_user_unactivated: 'Unactivated',
    x_view: 'View',
    x_renew: 'Renew',
    x_copy: 'Copy',
    x_rename: 'Rename',
    x_moveto: 'Move',
    x_duplicate: 'Duplicate',
    x_users_in: 'Users In',
    x_resources_in: 'Resources In',
    x_renew_sharecode: 'Renew Sharecode',
    x_opened_modules: 'Opened Modules',
    x_in_team: 'In Team',
    x_in_project: 'In Project',
    x_in_workspace: 'In Workspace',
    x_enter_ws_pwd: 'Please enter password for workspace',
    x_file_name: 'File Name',
    x_move_to_header: 'Move to',
    x_copy_to_header: 'Copy to',
    x_unzip_to_header: 'Unzip to',
    x_open: 'Open',
    x_role_user: 'User',
    x_guest: 'Guest',
    x_role_dm: 'Data Manager',
    x_role_pm: 'Project Manager',
    x_role_admin: 'Admin',
    x_role_superadmin: 'Super Admin',
    x_share_code: 'Share Code',
    x_size: 'Size',
    x_table_header_progress: 'Progress',
    x_table_header_messgae: 'Message',
    x_table_header_expire_date: 'Expire Date',
    x_table_header_share_with: 'Share With',
    x_publish_service_tip: 'Esri Service Layer;Supermap 3D Service Layer;URL Template Imagery Layer;OGC Service Layer',
    x_publish_data_tip: 'Media;Point Cloud (las);GeoTiff;3D Tiles;OBJ;Trajectory (csv);GeoJSON;KML;FILE......',
    x_please_contact: 'Please Contact',
    x_choosed: 'Choosed',
    x_private: 'Private',
    x_privacy: 'Visibility',
    x_public: 'Public',
    x_data_source: 'Data Source',
    x_fill_all_fields: 'Please fill all fields',
    x_next: 'Next',
    x_region: 'Region',
    x_product_plan: 'Plan',
    x_max_online_users: 'Concurrent online user limit',
    x_gen_share_link: 'Generate Share Link',
    x_share_link_clipboard: 'Share link copied to clipboard',
    x_qrcode: 'QR Code',
    x_root_folder_not_operable: 'Root folder can not be manipulated',
    x_visit: 'Visit',
    x_workspace_share: 'Workspace Share',
    x_username_or_teamname_input: 'User name or Team name',
    x_layer_property: 'Layer Property',
    x_default_open: 'Default Open',
    x_structure: 'Structure',
    x_preview: 'Preview',
    x_add_layer_group: 'Add Group',
    x_close_preview: 'Close Preview',
    x_unsupport_notice: 'This data type does not support automatic publishing. Our support team will contact you later after submitting the resource publishing request.',
    x_layer_preview_tip: 'Drag and drop to adjust order; Click to update name; Click add button to add layer group',
    x_query_service_url: 'Query Data Service URL',
    x_not_selectable: 'Not Selectable',
    x_enable_query: 'Enable Query',

    x_layer_s3m: 'S3M',
    x_open_failed: 'failed to open',

    x_attr_exp_num_rec_str: 'Edit failed. This field only allows numeric values.',
    x_shp_feat_geom_edit: 'Edit feature geometry',
    x_shp_feat_geom_delete: 'Delete feature',

    x_high: 'high',
    x_low: 'low',
    x_alert_str: '{0} recorded {1} reading of {2}: {3}',

    /// Finish the i18n for resource type and EO
    /*
    x_media: 'Media',
    x_ptc_las: 'Point Cloud (las)',
    x_geotiff: 'GeoTiff',
    x_panorama: 'Panorama images',
    x_3dtiles: '3D Tiles',
    x_trajectory_csv: 'Trajectory (csv)',
    x_kml: 'KML',
    x_obj: 'OBJ',
    x_shp: 'ESRI shapefile',
    x_file: 'File',
    x_bentley_dgn: 'Bentley DGN',
    x_dxf_dwg: 'Autodesk DXF/DWG',
    x_collada_dae: 'Collada DAE',
    x_revit: 'Autodesk Revit',
    x_gsm_csv: 'Points (csv)',
    */
    x_arcgis_server_map: 'ESRI map service',
    x_supermap_3d: 'SuperMap 3D service',
    x_url_template: 'URL template imagery',
    x_wms_layer: 'WMS service',
    x_wmts_layer: 'WMTS service',

    x_workspace_template: 'Workspace Interface Template',
    x_proj_template: 'Project Interface Template',
    // x_sdms: 'Default VR3D project',
    x_sdms: 'Default workspace template',
    x_lite: 'VR3D Lite',
    x_plan_template: 'Planning & Design Template',
    // x_iot_prototype: 'IoT Control Dashboard (prototype)',
    x_iot_prototype: 'IoT Control Dashboard',
    x_t2_portal_prototype: 'T2 Portal Prototype',
    x_dms: 'DMS',

    x_iot_ui_cust: 'Interface Customization',
    x_iot_enter_cust: 'Enter customization view',

    // The followings are applied to dynamically build system informations
    x_system: 'System',

    // Strings that was supposed to be encrypted from backend but temporarily moved back to frontend since we are not decrypting Chinese characters now. Hopefully migrate these to backend when possible.
    x_pricing_free: 'Free',
    x_pricing_std: 'Standard',
    x_pricing_adv: 'Advanced',
    x_pricing_pro: 'Pro',
    x_pricing_ult: 'Ultimate',
    x_edi_pms_lite: 'PMS Lite',
    x_edi_pms: 'PMS',
    x_pms_map_switch: 'To map view',
    x_pms_grid_switch: 'To grid view',
    x_edi_bim: 'BIM & GIS',
    x_media: 'Media',
    x_ptc_las: 'Point Cloud (las)',
    x_geotiff: 'GeoTiff',
    x_panorama: 'Panorama images',
    x_3dtiles: '3D Tiles',
    x_trajectory_csv: 'Trajectory (csv)',
    x_kml: 'KML',
    x_obj: 'OBJ',
    x_shp: 'ESRI shapefile',
    x_file: 'File',
    x_bentley_dgn: 'Bentley DGN',
    x_dxf_dwg: 'Autodesk DXF/DWG',
    x_collada_dae: 'Collada DAE',
    x_revit: 'Autodesk Revit',
    x_gsm_csv: 'Points (csv)',
    x_dem_geotiff: 'DEM (GeoTiff)',
    x_video: 'Video',
    x_plan: 'Design Option',
    x_design_scheme_project_list: 'Project List',
    x_design_scheme_create_new: 'Create New',
    x_design_scheme_close_scheme: 'Close Scheme',
    x_design_scheme_project_name: 'Project Name',
    x_design_scheme_last_update: 'Last Update',
    x_design_scheme_option_name: 'Option Name',
    x_design_scheme_step_1: 'Step 1.',
    x_design_scheme_step_2: 'Step 2.',
    x_design_scheme_step_3: 'Step 3.',
    x_design_scheme_step_4: 'Step 4.',
    x_design_scheme_step_5: 'Step 5.',
    x_design_scheme_step_6: 'Step 6.',
    x_scheme_management: 'Scheme Management',

    x_design_scheme_site_boundary: 'Site Boundary',
    x_design_scheme_height_restriction: 'Height Restriction (mPD)',
    x_design_scheme_area: 'Area(sq.m)',
    x_design_scheme_max_dom_pr: 'Max. Permitted Domestic Plot Ratio',
    x_design_scheme_site_level: 'Site level(mPD)',
    x_design_scheme_max_nondom_pr: 'Max. Permitted Non-domestic Plot Ratio',
    x_design_scheme_storey_restriction: 'Storey Restriction',
    x_design_scheme_site_coverage: 'Site Coverage(%)',
    x_design_scheme_dom_pr: 'Total Domestic Plot Ratio',
    x_design_scheme_nondom_pr: 'Total Non-Domestic Plot Ratio',
    x_design_scheme_composite_formula: 'Use Composite Formula',
    x_design_scheme_podium_pr: 'Total Podium Plot Ratio',
    x_design_scheme_nondom_tower_pr: 'Non-Domestic Tower Plot Ratio',
    x_design_scheme_basement_pr: 'Basement Plot Ratio',

    x_outside_site: 'Drawing outside site',
    x_collide_nba: 'Drawing clash with non-building area',
    x_collide_block: 'Drawing clash with existing buildings',

    x_dom_concession: 'Domestic concession (%)',
    x_nondom_concession: 'Non-domestic concession (%)',
    x_dom_floor_height: 'Domestic floor height (m)',
    x_nondom_floor_height: 'Non-domestic floor height (m)',

    x_select_site: 'Select site',
    x_all: 'All',

    x_design_scheme_domestic: 'Domestic Tower',
    x_design_scheme_podium: 'Podium',
    x_design_scheme_nondom: 'Non-Domestic Tower',
    x_design_scheme_floor_height: 'Floor to Floor Height',
    x_design_scheme_lobby_height: 'Lobby Floor Height',
    x_design_scheme_refuge_height: 'Refuge Floor Height',
    x_design_scheme_gfa_concession: 'GFA Concession(%)',
    x_design_scheme_tower_group_no: 'No. of Tower Type(s)',
    x_design_scheme_use_up_podium: 'Use up podium P.R.',
    x_design_scheme_draw_podium: 'Draw Podium',

    x_design_scheme_tower_type: 'Tower Type',
    x_design_scheme_tower_height_restriction: 'Max Building Height (mPD)',
    x_design_scheme_tower_no: 'No. of Towers',
    x_design_scheme_flat_size: 'Average Flat Size (sq.m)',
    x_design_scheme_flat_no: 'No. of Flats per Floor',
    x_design_scheme_footprint: 'Footprint Size (sq.m)',
    x_design_scheme_build_on: 'Building On',
    x_design_scheme_build_form: 'Building Form',
    x_design_scheme_site: 'Site',
    x_design_scheme_podium_residual: 'Podium Residual',
    x_design_scheme_dom_block_type: 'Domestic Block Types',
    x_design_scheme_nondom_block_type: 'Non-domestic Block Types',
    x_design_scheme_geom_block_type: 'Geometry Blocks',
    x_design_select_footprint: 'Select footprint for ',

    x_design_scheme_avail_towers: 'Available Towers to Add',
    x_design_scheme_typ_floor: 'No. of Typical Floors',
    x_design_scheme_ref_floor_count: 'No. of Refuge Floors',
    x_design_scheme_lobby_floor_count: 'No. of Lobby Floors',
    x_design_scheme_total_floor_count: 'Total No. of Floors',
    x_design_scheme_flat_no_tower: 'No. of Flats per Tower',
    x_design_scheme_tower_height: 'Building Height (m)',
    x_design_scheme_tower_mpd: 'Building Height (mPD)',
    x_design_scheme_floor_no_pod: 'No. of Floors',
    x_design_scheme_footprint_pod: 'Podium Footprint Size',
    x_design_scheme_gfa_pod: 'Podium GFA',
    x_design_scheme_do_name: 'Design Option Name:',
    x_project_design_scheme: 'Project & Design Option',

    // IoT and IoT template related (device/chart management)
    x_add_chart: 'Add new chart',
    x_chart: 'Chart',
    x_device: 'Device',
    x_form: 'Form',
    x_table: 'Table',
    x_monitor: 'monitor',
    x_device_info: 'Device Info',
    x_alert_level: 'Alert Level',
    x_device_name: 'Device Name',
    x_generic: 'Generic',
    x_chart_type: 'Chart type',
    x_chart_line: 'Line chart',
    x_chart_bar: 'Bar chart',
    x_chart_pie: 'Pie chart',
    x_chart_gauge: 'Gauge chart',
    x_reading_column: 'Reading column',
    x_time_column: 'Date time column',
    x_data_column: 'Data column',
    x_series_column: 'Series column',
    x_time_type: 'Visulization period',
    x_time_range: 'Date range',
    x_time_last: 'Recent',
    x_prev_days: 'Recent days',
    x_chart_line_color: 'Chart color',
    x_max_val: 'Max value',
    x_min_val: 'Min value',
    x_set_unit: 'Set unit',
    x_set_fontsize: 'Set font size',
    x_real_time_img: 'Real time photo',
    x_is_iot_link_layer: 'Link to IoT layer',
    x_link_layer: 'Linked layer',

    x_metadata: 'Metadata',
    x_attr: 'Attributes',
    x_rec: 'Record',
    x_warn_rule: 'Alert Setting',
    x_alert_source: 'Alert Source',
    x_alert_alias: 'Alert Alias',
    x_alert_value: 'Alert Value',
    x_alert_log: 'Alert Log',
    x_base_info: 'Basic Info',
    x_update_time: 'Update time',
    x_update_frequency: 'Update Frequency',
    x_update_frequency_tip: 'The setting of update frequency would be applied for all devices under this category',

    x_text: 'Text',
    x_num: 'Number',
    x_attachment: 'Attachment',
    x_time: 'Time',
    x_int: 'Integer',
    x_bigint: 'Big integer',
    x_float: 'Float number',

    x_col_name: 'Field name',
    x_col_alias: 'Alias',
    x_col_type: 'Field type',
    x_value: 'Value',

    x_col_condition: 'Condition',
    x_col_value: 'Alert Value',
    x_col_level: 'Alert Level',
    x_col_memo: 'Alert Memo',

    x_col_max_val: 'Expected Maximum Value',
    x_col_min_val: 'Expected Minimum Value',

    x_visible_all: 'Visible to all',
    x_admin_check_user_count: 'Current system online users',
    x_company_check_user_count: 'Current company online users',

    x_download_model: 'Download Template',
    x_pilot_management: 'Pilot Management',
    x_operation_panel: 'Operation Panel',
    x_comfirm_delete: 'Are you sure to delete this field',

    x_del_site_confirm: 'Are you sure you want to delete this site?',
    x_add_podium: 'Add Podium',
    x_add_block: 'Add Block',
    x_del_build_confirm: 'Are you sure you want to delete this building?',
    x_dev_param: 'Development Parameters',
    x_site_area: 'Site Area',
    x_non_building_area: 'Non-Building Area',
    x_site_no: 'No. of Sites',
    x_podiums_no: 'No. of Podiums',
    x_blocks_no: 'No. of Blocks',
    x_site_coverage: 'Site Coverage',
    x_mpd_max_height: 'Max Height in mPD',
    x_gfa_sum: 'GFA Summary',
    x_total_gfa: 'Total GFA',
    x_dom_gfa: 'Domestic GFA',
    x_n_dom_gfa: 'Non-domestic Tower GFA',
    x_tt_plot_r: 'Total Plot Ratio',
    x_dom_plot_r: 'Domestic Plot Ratio',
    x_n_dom_plot_r: 'Non-Domestic Plot Ratio',
    x_del_dsp_firm: 'Are you sure to delete the Design Scheme Project “',
    x_del_dso_firm: 'Are you sure to delete the Design Scheme Option “',

    x_design_scheme_draw_block: 'Draw Block',
    x_domestic: 'Domestic',
    x_non_domestic: 'Non-Domestic',
    x_drawing_texture: 'Texture',
    x_upload_files: 'Upload files',
    x_change_footprint: 'Change Footprint',
    x_select_from_library: 'Select from Library',

    x_save_as: 'Save As',
    x_previous: 'Previous',
    x_design_scheme_podium_over_15: 'Podium height exceeds 15m.',
    x_footprint: 'Footprint',
    x_select: 'Select',

    x_design_concession: 'Percentage of GFA Concession(%)',
    x_design_basement_gfa: 'Accountable GFA of Basement(s)',
    x_design_account_gfa: 'Accountable GFA',
    x_design_account_gfa_total: 'Total Accountable GFA (Including Basement(s))',
    x_my_file: 'My File',
    x_all_file: 'All File',
    x_file_detail: 'File Detail',
    x_folder_content: 'Folder Content',
    x_storage_info_tip: 'Select file to view details',
    x_storage_info_tip2: 'This folder is empty',
    x_go_back: 'Go Back',
    x_move_to: 'Move To',
    x_folder: 'Folder',
    x_unzip: 'Unzip',
    x_unzip_to: 'Unzip To',
    x_copy_to: 'Copy To',
    x_move_tip: 'Cannot move file to itself or its subdirectory',
    x_copy_tip: 'Cannot copy file to itself or its subdirectory',
    x_upload_list: 'File upload list',
    x_upload_list_tip: 'Show only this upload task',

    x_my_resources: 'My Resources',
    x_my_participation: 'My Participation',
    x_view_type: 'view type',
    x_creator: 'Creator',
    x_reverse: 'Reverse',
    x_detail: 'Detail',
    x_detail_view: 'Detail View',
    x_visibility: 'visibility',
    x_add_component: 'Add component',
    x_content_properties: 'Content Properties',
    x_text_number: 'Text(number)',
    x_text_color: 'Text color',
    x_target_url: 'target URL',
    x_document: 'Document',
    x_advance_setting: 'Advanced Setting',
    x_chart_attribute_editor: 'Chart Attribute Editor',
    x_border: 'Border',
    x_border_style: 'Border style',
    x_border_thickness: 'Border thickness',
    x_border_color: 'Border color',
    x_component_title: 'Component Title',
    x_main_color: 'Main',
    x_sub_color: 'Sub',
    x_border_background: 'Border and background',
    x_background_picture: 'Background picture',
    x_background_color: 'Background color',
    x_background_gaussian_blur: 'Background Gaussian blur',
    x_confirm_add: 'Confirm add',
    x_text_size: 'Text size',
    x_video_stream_properties: 'Video stream properties',
    x_video_stream_preview_properties: 'Video stream preview properties',
    x_document_select_message: 'Please select at least one document',
    x_ungroup_document_select_message: 'Please select at least one document in ungrouped area',
    x_document_alias: 'Document Alias',
    x_picture: 'Picture',
    x_cancel_option: 'Cancel option',
    x_my_star: 'My Star',
    x_star: 'Star',
    x_cancel_star: 'Cancel Star',
    x_login_site_title: 'Login to VR3D CIM Platform',
    x_pwd_reset: 'Password Reset',
    x_login_with: 'Login with',
    x_forget_pwd_tip: 'If you have forgotten your password, please enter your account’s email address below and click "submit" button. You will receive an email that contains a new password.',
    x_forget_pwd_tip2: 'Password Reset, Please check your email for a new password.',
    x_sign_up_title: 'Sign up to VR3D CIM Platform',
    x_back_to_login: 'Back to Login Page',
    x_send_verification_code: 'Send Verification Code',
    x_verification_code: 'Verification Code',
    x_send_verification_code_tip: 'Verification Code has been sent to your email!',
    x_invite_user: 'Invite users',
    x_link_invitation: 'Link invitation',
    x_email_invitation: 'Email invitation',
    x_click_copy: 'Click Copy',
    x_invite_user_input_tip: 'Please select the user to receive the invitation (user name or mailbox can be searched)',
    x_invite_email_input_tip: 'Please select the mailbox to receive the invitation (user name or mailbox can be searched)',
    x_email: 'Email',
    x_send_invitation: 'Send invitation',
    x_copy_link_tip: 'Copy link succeed',
    x_comfirm_remove: "Are you sure to move {0} {1} out of the company?",
    x_generate_link: 'Generate link',
    x_validate_tip: 'Please select a role first',
    x_public_resource: 'Public Resource',
    x_dashboard: 'Dashboard',
    x_projects: 'Projects',
    x_settings: 'Settings',
    x_load_tip: 'All loaded, {total} in total',
    x_overview: 'Overview',
    x_members: 'Members',
    x_company: 'Company',
    x_online_help: 'Online help',
    x_feedback: 'Feedback',
    x_log_out: 'Log out',
    x_faq: 'FAQ',
    x_account: 'Account',
    x_admin: 'Admin',
    x_space_domain: 'Spatial domain name',
    x_space_capacity: 'Space capacity',
    x_workbench: 'Workbench',
    x_company_members: 'Company members',
    x_freq: 'Freq',
    x_delete_confirm: 'Are you sure to delete the selected file?',
    x_month: 'Month',
    x_add_resource: 'Add Resource',
    x_lock: 'Lock',
    x_chart_tip: 'Date range cannot be the same day',
    x_custom: 'Custom',
    x_event: 'Events',
    x_daily_visit: 'Daily Visit',
    x_workspace_visit: 'Workspace Visit',
    x_real_visit: 'real visit',
    x_anon_visit: 'anon visit',
    x_process: 'Process',
    x_defect: 'Defect',
    x_defect_type: 'Defect Type',
    x_refresh: 'Refresh',
    x_save_preview: 'Save Preview',
    x_by_type: 'By Type',
    x_by_user: 'By User',
    x_by_time: 'By Time',
    x_downloading: 'Downloading...',
    x_save_preview_success: 'Save Preview Success',
    x_project_document: 'Project Document',
    x_length_q_s: 'Length',
    x_perimeter: 'Perimeter',
    x_area_area: 'Area of area',
    x_area_sqm_dot: 'm²',
    x_angle_angle: 'Angle of angle',
    x_angle_angle_angle: '°',
    x_domain_name: 'Domain name',
    x_company_name: 'Company name',
    x_domain_name_empty_err: 'Domain name can not be empty',
    x_domain_name_invlidate: 'Incorrect domain name characters contains',
    x_retrieve_info_feature: 'Retrieve detail information of a map feature',
    x_start_ident: 'Start Identify',
    x_start_ident_point: 'By Point',
    x_start_ident_line: 'By Line',
    x_start_ident_polygon: 'By Polygon',
    x_start_ident_circle: 'By Circle',
    x_cancel_ident: 'Cancel Identify',
    x_danger_zone: 'Danger Zone',
    x_remove_project: 'Remove project',
    x_remove_project_tip: 'Deleting the project is deleted along with all of its associated data.',
    x_remove_project_tip2: 'This operation cannot be recovered!',
    x_remove_project_verify_title: 'Sensitive operation verification',
    x_remove_project_tip3: 'You are deleting project',
    x_project_name_verify_err: 'Project name validation error',
    x_enter_project_name_verify: 'Please enter a project name to verify',
    x_assets_category: 'Assets Category',
    x_add_category: 'Add Category',
    x_add_device: 'Add Device',
    x_cctv: 'CCTV',
    x_worker: 'Worker',
    x_gender: 'Gender',
    x_vehicle: 'Vehicle',
    x_personnel: 'Personnel',
    x_car_model: 'Model',
    x_car_plate: 'Plate',
    x_upload_batch_device: 'Upload Batch Device',
    x_method: 'Method',
    x_step: 'Step',
    x_setup_category: 'Setup Category',
    x_category_name: 'Category Name',
    x_record: 'Record',
    x_record_attr: 'Record Attributes',
    x_record_Info: 'Record Info',
    x_upload_draft: 'Upload Draft',
    x_draft_manage: 'Draft Manage',
    x_draft_info: 'Draft Information',
    x_login_title: 'Login title',
    x_login_background: 'Background',
    x_validate_invlidate: 'The content format is incorrect',
    x_days: 'Days',
    x_validity: 'Validity',
    x_validity_tip: 'Sorry, the sharing link has expired!',
    x_pwd_label: 'Please input workspace password',
    x_permanent: 'Permanently valid',
    x_report: 'Report',
    x_report_name: 'Report Name',
    x_company_private: 'Visible only to the company',
    x_company_power_tip: 'Sorry, you do not have permission to access this link!',
    x_draw_entity: 'Draw Entity',
    x_add_to_resource: 'Add to resource',
    x_upload_icon: 'Upload Icon',
    x_geojson: 'GEOJSON',
    x_add_to_workspace: 'Add to workspace',
    x_device_in_Workspace: 'Device in workspace',
    x_dashboard_logo: 'Dashboard logo',
    x_dashboard_theme: 'Dashboard theme',
    x_add_to_project: 'Add To Project',
    x_create_new_dashboard: 'Create New Dashboard',
    x_edit_dashboard: 'Edit Dashboard',
    x_change_hub: 'Change Hub',
    x_function: 'Function',
    x_osgb: 'OSGB',

    x_project_preview: 'Project preview',
    x_location: 'Location',
    x_icon_type: 'Icon Type',
    x_monitoring_management: 'Monitoring Management',
    x_monitoring_wall: 'Monitoring wall',
    x_add_monitoring_wall: 'Add Monitoring wall',
    x_map: 'Map',
    x_add_to_monitoring_wall: 'Add To Monitoring Wall',
    x_cctv_add: 'Add',
    x_cctv_remove: 'Remove',
    x_return_to_list: 'Return to list',
    x_richtext_tip: 'Click edit text',
    x_project_menu: 'Project Menu',
    x_minimize: 'Minimize',
    x_restore: 'Restore',
    x_device_detail: 'Device Detail',
    x_alert_detail: 'Alert Detail',
    x_comparison: 'Comparison',
    x_add_marker: 'Add Marker',
    x_add_Measurement: 'Add Measurement',
    x_marker_name: 'Marker Name',
    x_initial: 'Initial',
    x_initial_date: 'Initial Date',
    x_initial_reading: 'Initial Reading',
    x_measure_date: 'Measure Date',
    x_level: 'Level',
    x_diff_initial: 'Diff Initial',
    x_diff_previous: 'Diff Previous',
    x_last_reading_date: 'Last Reading Date',
    x_last_reading: 'Last Reading',
    x_last_diff_initial: 'Last Diff Initial',
    x_alarm: 'Alarm',
    x_team_name: 'Team Name',
    x_team_members: 'Team Members',
    x_team_menu: 'Team Menu',
    x_add_team_member: 'Add Team Member',
    x_username_input: 'User name',
    x_iot_category: 'IOT category',
    x_cctv_category: 'CCTV category',
    x_worker_category: 'Worker category',
    x_vehicle_category: 'Vehicle category',
    x_generic_alert: 'IOT alert',
    x_personnel_alert: 'Worker alert',
    x_vehicle_alert: 'Vehicle alert',
    x_cctv_alert: 'CCTV alert',
    x_generic_list: 'IOT list',
    x_personnel_list: 'Worker list',
    x_vehicle_list: 'Vehicle list',
    x_cctv_list: 'CCTV list',
    x_view_monitoring: 'View monitoring',
    x_iot_management: 'IOT Management',
    x_cctv_management: 'CCTV Management',
    x_worker_management: 'Worker Management',
    x_vehicle_management: 'Vehicle Management',
    x_datepicker: 'Date Picker',
    x_query_result: 'Query result',
    x_lower_limit: 'Lower Limit',
    x_upper_limit: 'Upper Limit',
    x_subscribe: 'Subscribe',
    x_unsubscribe: 'Unsubscribe',
    x_task_name: 'Task Name',
    x_model: 'Model',
    x_run: 'Run',
    x_subscribe_attribute: 'Subscribe Attribute',
    x_upper_clothes: 'Upper Clothes',
    x_lower_clothes: 'Lower Clothes',
    x_upper_clothes_color: 'Upper Clothes Color',
    x_lower_clothes_color: 'Lower Clothes Color',
    x_hat: 'Hat',
    x_vest: 'Vest',
    x_human_detection: 'Human Detection',
    x_vehicle_detection: 'Motor Vehicle Detection',
    x_step_size: 'Step Size',
    x_zone_name: 'Zone Name',
    x_zone_detail: 'Zone Detail',
    x_zone: 'Zone',
    x_upload_zone: 'Upload Zone',
    x_overview_zone_management: 'Zone',
    x_zone_list: 'Zone List',
    x_zone_category: 'Zone category',
    x_ai_event: 'Event',
    x_alert_center: 'Alert Center',
    x_push_system: 'Push System',
    x_push_email: 'Push Email',
    x_push_whatsapp: 'Push App',
    x_alert_col: 'Alert Col',
    x_push_staffing: 'Push staffing',
    x_bool: 'Bool',
    x_bool_value: 'Bool Value',
    x_rule_name: 'Rule Name',
    x_rule: 'Rule',
    x_add_member: 'Add member',
    x_forbidden_zone: 'The Forbidden Zone',
    x_label: 'Label',
    x_decorate: 'Decorate',
    x_decorate_type: 'Decorate Type',
    x_select_picture: 'Select picture',
    x_select_color: 'Select color',
    x_border_radius: 'Border radius',
    x_label_text: 'Label text',
    x_alignment: 'Alignment',
    x_align_left: 'Align Left',
    x_align_right: 'Align Right',
    x_align_center: 'Align Center',
    x_font_weight: 'Font weight',
    x_background_size: 'Background size',
    x_picture_source: 'Picture source',
    x_pic_upload: 'Picture Upload',
    x_net_address: 'Network address',
    x_interface_data: 'Interface Data',
    x_interface_address: 'Interface Address',
    x_interface_type: 'Interface Type',
    x_interface_calling_error: 'Please check the calling parameters or contact the administrator',
    x_response_data: 'Response Data',
    x_pie_chart_setting: 'Pie Chart Setting',
    x_value_setting: 'Value setting',
    x_set_ring_type: 'Set Ring Type',
    x_set_nightingale_rose: 'Set Nightingale Rose',
    x_auto_sort: 'Auto Sort',
    x_not_show_zero: 'Not Show Zero',
    x_show_value: 'Show Value',
    x_border_width: 'Border width',
    x_real_time: 'Real Time',
    x_time_format: 'Time format',
    x_time_format1: 'Date',
    x_time_format2: 'Date + hour, minute',
    x_time_format3: 'Date + hour, minute and second',
    x_time_format4: 'Date (No Year)',
    x_time_format5: 'Hour, minute',
    x_time_format6: 'Hour, minute and second',
    x_time_format7: 'Week',
    x_custom_format: 'Custom format',
    x_font_spacing: 'Font spacing',
    x_show_widget_windowstate: 'Show widget windowstate',
    x_widget_name: 'Widget name',
    x_weather: 'Weather',
    x_current_weather: 'Current weather',
    x_weather_forecast: 'Weather forecast',
    x_display_type: 'Display type',
    x_card: 'Card',
    x_show_weather_warning: 'Show weather warning',
    x_show_days: 'Show days',
    x_chart_title: 'Chart title',
    x_chart_title_color: 'Chart title color',
    x_inner_ring_size: 'Inner ring size',
    x_outer_ring_size: 'Outer ring size',
    x_show_legend: 'Show legend',
    x_label_position: 'Label position',
    x_device_type: 'Device type',
    x_generic_iot: 'Generic IOT',

    //workFlow
    action: {
      confirmClose: 'close ?',
      confirmDelete: 'delete ?',
      edit: 'Edit',
      publish: 'Publish',
      draft: 'Draft',
      delete: 'Delete',
      cancel: 'Cancel',
      confirm: 'Confirm',
      reset: 'reset',
      submit: 'submit',
      gettingData: 'get data',
      saveDraft: 'saveDraft',
      reject: 'reject',
      counterSign: 'counterSign',
      tempNode: 'tempNode',
      addSign: 'addSign',
      return: 'return',
      randomReturn: 'randomReturn',
      pass: 'pass'
    },

    userTaskList: {
      id: 'index',
      applyStatus: 'applyStatus',
      seachName: 'seachName',
      applyDate: 'applyDate',
      rangeSeparator: 'to',
      applyStartDate: 'startDate',
      applyEndDate: 'applyEndDate',
      approveTime: 'approveTime',

      query: 'query',
      processId: 'processId',
      applyDept: 'applyDept',
      seachNamePlaceholder: 'suport applySn applyRealName',
      applySn: 'applySn',
      applyTitle: 'applyTitle',
      tenantId: 'tenantId',
      applyRealName: 'applyRealName',
      applyDeptName: 'applyDeptName',
      remarks: 'remarks',
      processName: 'processName',
      placeSelect: 'placeSelect',
      createTime: 'createTime',
      currentApproveName: 'currentApproveName',
      action: 'action',
      view: 'view',
      approve: 'approve',
      batchPass: 'Batch Pass',
      todo: 'todo',
      apply: 'apply',
      havedo: 'havedo',
      draft: 'draft',
      status1: 'not pass',
      status2: 'claimed',
      status3: 'pass',
      status4: 'canceled',
      launchProcess: 'Launch Process'
    },

    formList: {
      formKey: 'formKey',
      formName: 'formName',
      status: 'status',
      query: 'query',
      addForm: 'addForm',
      placeSelect: 'place select',
      id: 'id',
      deleted: 'deleted',
      preview: 'preview',
      update: 'update',
      status1: 'valid',
      status0: 'invalid',
      select: 'select',
      design: 'design',
      copy: 'copy',
      formType: 'formType',
      tenantId: 'tenantId',
      validState: 'status',
      remarks: 'remarks',
      operatorName: 'operatorName',
      updateTime: 'updateTime',
      action: 'action',
      sort: 'sort',
      formType1: 'PC',
      formType2: 'Mobile'

    },

    processList: {
      processId: 'processId',
      processKey: 'key', // processKey
      processName: 'name', // processName
      status: 'status',
      query: 'query',
      addProcess: 'addProcess',
      placeSelect: 'place select',
      id: 'id',
      deleted: 'deleted',
      preview: 'preview',
      update: 'update',
      tenantId: 'tenantId',
      remarks: 'remarks',
      operatorName: 'operatorName',
      updateTime: 'updateTime',
      action: 'action',
      showDiagram: 'detail',
      sort: 'sort',
      processAbbr: 'abbreviation', // processAbbr
      companyId: 'company',
      companyCode: 'companyCode',
      versionManager: 'version',
      editDiagram: 'edit',
      publish: 'publish',
      definitionId: 'definitionId',
      processDetailId: 'processDetailId',
      setDefault: 'setDefault',
      launchProcess: 'launchProcess',
      processBelong: 'process', // processBelong
      workspaceBelong: 'workspace', // workspaceBelong
      projectBelongPlaceholder: 'Please select the project',
      workspaceBelongPlaceholder: 'Please select the workspace',
      noDataTxt: 'Please select the project first',
      status1: 'published',
      status2: 'unpublished',
    },

    historyList: {
      id: '序号',
      approveRealName: 'approveRealName',
      taskName: 'taskName',
      createTime: 'approveTime',
      approveOpinion: 'approveOpinion'

    },

    approve: {
      nodeName: 'name', // nodeName
      nodeUserName: 'userName', // nodeUserName
      approveAction: 'action', // approveAction
      approveOpinion: 'opinion' // approveOpinion
    },


    table: {
      confirm: 'Confirm',
      cancel: 'Cancel',
    },

    el: {
      select: {
        noData: 'No Data',
      },
      table: {
        emptyText: 'No Data',
      },
      messagebox: {
        confirm: 'Confirm',
        cancel: 'Cancel',
      }
    },

    loading: {
      text: 'Loading'
    },

    flowList: {
      workflow: 'Workflow',
      launchProcess: 'Launch Process',
      toDoProcess: 'To-do Process',
      doneProcess: 'Done Process',
      myProcess: 'My Process',
      orderNumber: 'orderNumber',
      title: 'title',
      processName: 'processName',
      applyUserName: 'applicant',
      applyDate: 'applyDate',
      createTime: 'createTime',
      approveTime: 'approveTime',
      taskStatus: 'taskStatus',
      remarks: 'remarks',
      currentApproveName: 'currentApproveName',
      action: 'action',
      workflowDesign: 'Workflow Design',
      formDesign: 'Form Design',
      processDesign: 'Process Design',
      valid: 'valid',
      invalid: 'invalid',
      published: 'published',
      unpublished: 'unpublished'
    },

    x_dashboard_name: 'Wisdom construction of Zhejiang Harbor ocean Engineering Construction Company',
    x_temperature: 'Temperature',
    x_theme_colors: 'Theme colors',
    x_theme_purple: 'Purple',
    x_theme_blue: 'Blue',
    x_theme_green: 'Green',
    x_theme_red: 'Red',
    x_theme_orange: 'Orange',
    x_environment_monitoring_system: 'Environment Monitoring System',
    x_icon_pm_num: 'PM 2.5',
    x_icon_pm_10: 'PM 10',
    x_icon_tsp: 'TSP',
    x_icon_noise: 'Noise',
    x_icon_Temperature: 'Temperature',
    x_wind_speed: 'Wind speed 32.15',
    x_atmospheric_pressure: 'Atmospheric pressure',
    x_humidity: 'Humidity',

    x_location_search: 'Location Search',
    x_search_placeholder_location: 'Building,Addresses,Places,Lots(e.g.DD381 Lot 953)',
    x_dmspoint: 'DMS Point',
    x_alarmdms: 'Alarm Point',
    x_alertdms: 'Alert Point',
    x_actiondms: 'Action Point',
    x_legend: 'Legend',
    Lot: 'Lot',
    Address: 'Address',
    Building: 'Building',
    Places: 'Places',
    x_playback: 'Trajectory PlayBack',
    x_flyplan:'Fly Plan',
    x_flyplan_no_flyplan:'No Fly Plan',
    x_base:'Basemap',
    x_baselayer:'Basemap'
  },

  'zh-CN':{},
  'zh-TW':{}
};