
import CoordTransform from './Components/CoordTransform.js';
import Cookies from "js-cookie";
class Util {
  constructor () {
  }

  static get CoordTransform () {
    return CoordTransform;
  }

  static getToken () {
    let token = Cookies.get("sdms_token");
    if (!token)
      token = Cookies.get("workspace_token");

    return token;
  }
}

export default Util;