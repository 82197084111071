<style lang="less">
@import "./appwrapper.less";
</style>

<template>
  <div id="appWrapper">
    <section
      v-if="roleId<3"
      class="app-menu-nav"
    >
      <div id="appMenu">
        <div class="app-menus">
          <div class="app-logo">
            <svg-icon icon-class="icon_vr3d_logo" class="sys-logo"></svg-icon>
          </div>
           <div
            :class="{
              'app-menu-item': true,
              'app-menu-item-active': routername == 'projects' ? true : false,
            }"
          >
            <Tooltip placement="right" :content="$t('x_projects')">
              <div class="app-menu-item-content" @click="toPath('projects')">
                <i class="fas fa-tasks"></i>
                <span class="item-name">{{ $t('x_projects') }}</span>
              </div>
            </Tooltip>
          </div>

          <div
            :class="{
              'app-menu-item': true,
              'app-menu-item-active': routername == 'resources' ? true : false,
            }"
          >
            <Tooltip placement="right" :content="$t('x_menu_resources')">
              <div class="app-menu-item-content" @click="toPath('resources')">
                <i class="fas fa-layer-group"></i>
                <span class="item-name">{{ $t('x_menu_resources') }}</span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </section>
    <section class="app-container">
      <nav class="app-top-nav" v-if="roleId<1">
        <div class="nav-info">
          <span class="plain-company-logo" @click="roleId==5 ? toPath('projects') : toPath('overview')"
            ><img :src="photourl(companyProfile.logo)" alt=""
          /></span>
          <span class="plain-company-name" @click="roleId==5 ? toPath('projects') : toPath('overview')">{{
            companyProfile.name
          }}</span>
          <div v-for="(h, idx) in navPaths" :key="idx">
            <span class="plain-break" v-if="h.type == 'break'">/</span>
            <span
              class="plain-nav-title"
              v-if="h.type == 'title'"
              @click="navPathClicked(h)"
              >{{ h.title }}</span
            >
          </div>
        </div>
        <div class="nav-setting">
          <div class="noti-btn">
            <Poptip placement="bottom-end" trigger="click" @on-popper-show="showQrcode" @on-popper-hide="hideQrcode">
              <div class="circle-btn"><i class="fas fa-link"></i></div>
              <div class="code-body" slot="content">
                <img v-if="qrcodeUrl" :src="qrcodeUrl" />
                <div v-else class="noImg"><Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon></div>
                <div class="code-tip">{{ $t('x_code_tip') }}</div>
              </div>
            </Poptip>
          </div>
          <div class="noti-btn">
            <Poptip placement="bottom-end" trigger="click">
              <Badge
                  :count="unReadTaskNum"
                  type="error"
                  @click="loadtasks"
                  :offset="[0,0]"
                  v-if="(roleId!=4 && roleId!=5)"
                  class="processing-badge"
                >
                <div class="circle-btn"  @click="loadtasks"><i class="fas fa-bell"></i></div>
              </Badge>
              <div class="task-body" slot="content">
                <Table
                  max-height="300"
                  ref="taskTable"
                  size="small"
                  :data="tasks.list"
                  :columns="taskColumns"
                  :highlight-row="true"
                  :row-class-name="rowClassName"
                  @on-row-dblclick="clearTask"
                >
                  <template slot-scope="{ row }" slot="progress">
                    <Progress
                      :percent="parseFloat((row.complete*100/row.total).toFixed(1))"
                      v-if="row.state==0 && row.complete"
                      :stroke-width="5"
                    />
                  </template>
                  <template slot-scope="{ row }" slot="action">
                    <Button v-if="row.state==1" type="text" @click="clearTask(row)">
                      <i class="fas fa-times-circle"></i>
                    </Button>
                    <Button v-if="row.state==0" type="text" @click="cancelTask(row)">{{$t('x_cancel')}}</Button>
                  </template>
                </Table>
              </div>
            </Poptip>
          </div>
          <div class="account-btn">
            <Poptip placement="bottom-end" trigger="hover">
              <Avatar :src="userPhotourl()"></Avatar>
              <div class="sec-menu" slot="content">
                <Button
                  type="text"
                  long
                  class="sec-menu-item"
                  @click="toPath('profile')"
                  ><i class="fas fa-user-circle"></i> {{ $t('x_account') }}</Button
                >
                <Button
                  type="text"
                  long
                  class="sec-menu-item"
                  @click="toPath('hub')"
                  ><i class="fas fa-building"></i> {{ $t('x_change_hub') }}</Button
                >
                <Poptip class="inline-pop" placement="left" trigger="hover">
                  <Button type="text" long class="inline-pop-btn"
                    ><div>
                      <i class="fas fa-palette"></i> {{ $t("x_theme") }}
                    </div>
                    <i class="fas fa-angle-right">

                    </i>
                  </Button>
                  <div class="inline-pop-content" slot="content">
                    <Button type="text" long @click="chageTheme('sdmslight')">
                      {{ $t("x_theme_light") }}
                      <div class="t-light-color"></div>
                    </Button>
                    <Button type="text" long @click="chageTheme('sdmsdark')">
                      {{ $t("x_theme_dark") }}
                      <div class="t-dark-color"></div>
                    </Button>
                    <Button type="text" long @click="chageTheme('sdmsblue')">
                      {{ $t("x_theme_blue") }}
                      <div class="t-blue-color"></div>
                    </Button>
                    <Button type="text" long @click="chageTheme('sdmsred')">
                      {{ $t("x_theme_red") }}
                      <div class="t-red-color"></div>
                    </Button>
                    <Button type="text" long @click="chageTheme('sdmspurple')">
                      {{ $t("x_theme_purple") }}
                      <div class="t-purple-color"></div>
                    </Button>
                     <Button type="text" long @click="chageTheme('sdmsdarkblue')">
                      {{ $t("x_theme_dark_blue") }}
                      <div class="t-darkblue-color"></div>
                    </Button>
                  </div>
                </Poptip>
                <Poptip class="inline-pop" placement="left" trigger="hover">
                  <Button type="text" long class="inline-pop-btn"
                    ><div><i class="fas fa-globe"></i> {{ $t("x_lang") }}</div>
                    <i class="fas fa-angle-right"></i>
                  </Button>
                  <div class="inline-pop-content" slot="content">
                      <Button type="text" long @click="changeLang('en-US')">
                        English
                      </Button>
                      <Button type="text" long @click="changeLang('zh-TW')">
                        繁體中文
                      </Button>
                      <Button type="text" long @click="changeLang('zh-CN')">
                       简体中文
                      </Button>
                  </div>
                </Poptip>
                <Button type="text" long class="sec-menu-item" @click="logout"
                  ><i class="fas fa-power-off"></i> {{ $t('x_log_out') }}</Button
                >
              </div>
            </Poptip>
          </div>
        </div>
      </nav>
      <section
               :class="{
              'app-body': roleId <1,
              'app-body1': roleId >=1,
            }"><router-view /></section>
    </section>
  </div>
</template>

<script>
import _ from "lodash";
import Cookies from "js-cookie";
import Service from "../../libs/service.js";

export default {
  components: {},
  data() {
    return {
      routername: "",
      navPaths: [],
      roleId: 5,
      unReadTaskNum: 0,
      checkTaskIntervalID: null,
      theme: window.localStorage.theme || "sdmslight",
      myProfile: {
        settings: {
          language: "",
          theme: "",
        },
      },
      companyProfile: {},
      tasks: {
        keywords: "",
        total: 0,
        page: 1,
        pageSize: 10,
        list: [],
        originresult: [],
        checked: [],
        selected: {},
        taskModal: false,
      },
      taskColumns: [
        {
          title: this.$t("x_name"),
          key: "name",
            ellipsis: true,
          tooltip: true,
        },
        {
          title: this.$t("x_create_date"),
          key: "startTime",
          sortable: true,
          className: "folder-info-column",
        },
        {
          title: this.$t("x_table_header_progress"),
          slot: "progress",
        },
        {
          title: this.$t("x_table_header_messgae"),
          key: "message",
          ellipsis: true,
          tooltip: true,
          className: "folder-info-column",
        },
        {
          title: "",
          slot: "action",
          className: "folder-info-column",
          width: 80,
          align: "right",
        },
      ],
      usrSocket: null,
      qrcodeUrl: null,
    };
  },
  computed: {},
  methods: {
    init() {
      window.navVUI = this;

      function removeGuestCookies() {
        if (window.localStorage.isUnLogin) {
          Cookies.remove("sdms_token");
          Cookies.remove("access");
          Cookies.remove("user");
          Cookies.remove("userId");
          Cookies.remove("companyId");
          Cookies.remove("workspace_token");
        }
      }

      window.onbeforeunload = function () {
        removeGuestCookies();
      };

      window.addEventListener(
        "beforeunload",
        function (e) {
          removeGuestCookies();
        },
        false
      );

      this.roleId = Cookies.get("access");

      this.checkTask();

      var that = this;
      this.checkTaskIntervalID = setInterval(function () {
        that.checkTask();
      }, 30000);

      this.getUserProfile();
      this.getCompanyProfile();

      // directly give function 'checkTask' as a public one to be called
      window.checkTask = this.checkTask;
    },
    navPathClicked(p) {
      if (p.path) {
        this.toPath(p.path, p.query);
      }
      if (p.page) {
        if (p.page == "storage") {
          if (p.index) {
            _.last(this.$children).toPath(p.title, p.index);
          } else {
            _.last(this.$children).toPath();
          }
        }
      }
    },
    toPath(url, query) {
      if (url) {
        if (url=='resources') {
          this.$router.push({
            name: 'allresources',
            query: {
              resy: 'all',
            }
          });
        }
        else if (url=='projects') {
          let queryString = `pid=1107&title=Village Demo&workspace=true`;
          // let queryBase64 = btoa(queryString);
          let queryBase64 = btoa(unescape(encodeURIComponent(queryString)));
          this.$router.push({
            name: 'workspacelite',
            query: {
              p: queryBase64,
              prjid: 996,
              wid: 1107
            }
          });
        }
        else {
          this.$router.push({
            name: url,
            query: query,
          });
        }
      }
    },
    rowClassName(row, index) {
      if (row.state == 0) {
        return "task-underprocessing";
      } else if (row.state == 1) {
        return "task-unread";
      }
      return "";
    },
    loadtasks() {
      this.tasks.taskModal = true;
      this.cleanTasks();
      this.checkTask();
    },
    checkTask() {
      if (!Cookies.get("sdms_token")) {
        this.isGuest = true;
        return;
      }

      if (window.localStorage.isUnLogin) {
        this.isGuest = true;
        clearInterval(this.checkTaskIntervalID);

        if (this.$route.name != "viewer" && this.$route.name != "login") {
          this.toConsole();
        }

        return;
      }

      if (this.isGuest) this.isGuest = false;

      var that = this;
      Service.request("get", "/progress/list", "", function (dt) {
        if (dt) {
          let unreadtasks = _.filter(dt.data, function (o) {
            return o.state != 2;
          });
          // _.each(unreadtasks, (k) => {
          //   k.message = k.message;
          // });
          that.tasks.list = unreadtasks;
          that.unReadTaskNum = unreadtasks.length;
        }
      });
    },
    cancelTask(t) {
      if (t.state == 0) {
        var that = this;
        Service.request(
          "delete",
          "/progress/cancel",
          {
            progressId: t.progressId,
            layerId: t.layerId,
          },
          function (dt) {
            if (dt) {
              that.checkTask();
            }
          }
        );
      }
    },
    clearTask(t) {
      if (t.state == 1) {
        t.state = 2;
        var that = this;
        Service.request("put", "/progress/update", t, function (dt) {
          if (dt) {
            that.checkTask();
          }
        });
      }
    },
    cleanTasks() {
      Service.request("delete", "/progress/clean", "", function (dt) {
        // if (dt) {
        // }
      });
    },
    logout() {
      if (this.usrSocket) {
        console.log("Enter the logout log");
        /// TODO: tell the socket this this is a logout
        // this.usrSocket.send(Cookies.get("companyId"));
        this.usrSocket.close();
        this.usrSocket = null;
      } else {
        console.warn("wss connection");
      }

      var that = this;
      Service.request("post", "/sys/logout", "", function (dt) {
        clearInterval(that.checkTaskIntervalID);
        that.$store.commit("logout", this);
        window.localStorage.isUnLogin = undefined;
        delete window.localStorage.isUnLogin;

        that.$router.push({
          name: "login",
        });
      });
    },
    toConsole() {
      if (window.localStorage.isUnLogin) {
        clearInterval(this.checkTaskIntervalID);
        Cookies.remove("sdms_token");
        window.localStorage.isUnLogin = undefined;
        delete window.localStorage.isUnLogin;

        let that = this;
        /// In case the socket is not yet ready, wait for the socket ready and then execute
        let socketWaitID = setInterval(function () {
          if (that.usrSocket) {
            /// TODO: tell the socket that this is a logout
            that.usrSocket.send(Cookies.get("companyId"));
            that.usrSocket.close();
            that.usrSocket = null;
            clearInterval(socketWaitID);
            socketWaitID = undefined;
          }
        }, 500);

        if (this.usrSocket) {
          console.log("Enter the logout log");
          /// TODO: tell the socket this this is a logout
          // this.usrSocket.send(Cookies.get("companyId"));
          this.usrSocket.close();
          this.usrSocket = null;
        } else {
          console.warn("wss connection");
        }
        this.$store.commit("logout", this);

        this.$router.push({
          name: "login",
        });
      } else {
        // Query parameters does not seem to work, so modifying the local storage directly
        let routeData = this.$router.resolve({
          name: "projects",
        });
        window.open(routeData.href, "_self");
      }
    },
    getUserProfile() {
      if (!Cookies.get("sdms_token") && !Cookies.get("workspace_token")) {
        setTimeout(this.getUserProfile, 1000);
        return;
      }

      var that = this;
      Service.request(
        "get",
        "/sys/user/info",
        "",
        function (dt) {
          that.myProfile = dt.data;

          Cookies.set("companyId", dt.data.companyId);
          Cookies.set("access", dt.data.roleId);
          Cookies.set("status", dt.data.status);

          if (that.myProfile.settings && that.myProfile.settings != "") {
            that.myProfile.settings = JSON.parse(that.myProfile.settings);
            if (that.myProfile.settings.theme) {
              that.toggleTheme(that.myProfile.settings.theme);
            }
          }


        },
        function (err) {}
      );
    },
    getCompanyProfile() {
      if (
        (!Cookies.get("sdms_token") && !Cookies.get("workspace_token")) ||
        !Cookies.get("companyId")
      ) {
        setTimeout(this.getCompanyProfile, 1000);
        return;
      }

      var that = this;
      Service.request(
        "get",
        `/sys/company/info/${Cookies.get("companyId")}`,
        "",
        function (dt) {
          that.companyProfile = dt.data;

          if (that.companyProfile.srid && that.companyProfile.srid != "") {
            Cookies.set("srid", that.companyProfile.srid);
          }

          if (that.$route.name == "viewer") {
            /// TODO: Apply addon settings
            let addonSettings = that.companyProfile.menuSettings;
            try {
              addonSettings = JSON.parse(addonSettings);
              console.log(addonSettings);
              window.addonSettings = addonSettings;
            } catch (e) {
              // Skipping catch action, since this mainly means no json set
              /// Not safe since all addons opened by default
              window.addonSettings = {};
            }
          }
        },
        function (err) {}
      );
    },
    showQrcode() {
      this.getUserQrcode();
    },
    hideQrcode() {
      this.qrcodeUrl = null;
    },
    getUserQrcode() {
      let that = this;
      Service.request(
        "get",
        `/wx/qrcode/url/${Cookies.get("userId")}`,
        "",
        function (dt) {
          if(dt.data) {
            that.qrcodeUrl = dt.data;
          }
        },
        function (err) {
        }
      );
    },
    photourl(p) {
      if (!p || p == "") {
        return null;
      } else {
        // return `${window.Baseurl}${p}?token=${Cookies.get("sdms_token")}`;
        return `${p}`;
      }
    },
    userPhotourl() {
      if (!this.myProfile["photo"] || this.myProfile["photo"] == "") {
        return `https://via.placeholder.com/64x64/F0F0F0/999999/?text=Avatar`;
      } else {
        // return `${window.Baseurl}${this.myProfile["photo"]}?token=${Cookies.get(
        //   "sdms_token"
        // )}`;
        return `${this.myProfile["photo"]}`;
      }
    },
    toggleTheme(t) {
      let themeLink = document.querySelector("body");
      themeLink.setAttribute("class", t);
      this.theme = window.localStorage.theme = t;
    },
    changeLang(n) {
      this.$store.commit("switchLang", n);
      this.$i18n.locale = n;
      if (window.map)
        window.map.changeBasemapLabelLang();
      if (window.map2D)
        window.map2D.changeBasemapLabelLang();
      this.saveProfile("language", n);
    },
    chageTheme(t) {
      let themeLink = document.querySelector("body");
      themeLink.setAttribute("class", t);
      this.theme = window.localStorage.theme = t;
      this.saveProfile("theme", t);
    },
    saveProfile(k, v) {
      var that = this;
      let b = that.myProfile["settings"];
      b[k] = v;
      let p = {
        userId: that.myProfile["userId"],
        companyId: Number(Cookies.get("companyId")),
        roleId: Number(Cookies.get("access")),
        status: Number(Cookies.get("status")),
        settings: JSON.stringify(b),
      };

      Service.request(
        "post",
        "/sys/user/update",
        p,
        function (dt) {},
        function (err) {},
        {
          skipSuccess:true
        }
      );
    },
  },
  watch: {
    $route(to) {
      this.routername = to.name;
      if (to.name == "allresources" || to.name == "myresources" || to.name == "myparticipation" || to.name == "resourcemap") {
        this.navPaths = [
          {
            type: "break",
          },
          {
            type: "title",
            title: this.$t('x_overview_resourve'),
          },
        ];
      } else if (to.name == "storage") {
        this.navPaths = [
          {
            type: "break",
          },
          {
            type: "title",
            title: this.$t('x_overview_resource_storage'),
          },
        ];
      } else if (to.name == "overview") {
        this.navPaths = [
          {
            type: "break",
          },
          {
            type: "title",
            title: this.$t('x_overview'),
          },
        ];
      } else if (to.name == "users") {
        this.navPaths = [
          {
            type: "break",
          },
          {
            type: "title",
              title: this.$t('x_admin'),
          },
          {
            type: "break",
          },
          {
            type: "title",
            title: this.$t('x_members'),
          },
        ];
      } else if (to.name == "profile") {
        this.navPaths = [
          {
            type: "break",
          },
          {
            type: "title",
            title: this.$t('x_menu_my_profile'),
          },
        ];
      } else if (to.name == "company") {
        this.navPaths = [
          {
            type: "break",
          },
          {
            type: "title",
              title: this.$t('x_menu_company_profile'),
          },
        ];
      } else if (to.name == "statistics") {
        this.navPaths = [
          {
            type: "break",
          },
          {
            type: "title",
           title: this.$t('x_admin'),
          },
          {
            type: "break",
          },
          {
            type: "title",
             title: this.$t('x_menu_statistics_dashboard'),
          },
        ];
      } else if (to.name == "smartsite_projectworkspace") {
        for (let i = 0; i < this.navPaths.length; i++) {
          const item = this.navPaths[i];
          if(item.path && item.path == "smartsite_projectworkspace") {
            this.navPaths.splice(i+1,this.navPaths.length-(i+1));
            break;
          }
        }
      } else if (to.name == "projectworkspace") {
        for (let i = 0; i < this.navPaths.length; i++) {
          const item = this.navPaths[i];
          if(item.path && item.path == "projectworkspace") {
            this.navPaths.splice(i+1,this.navPaths.length-(i+1));
            break;
          }
        }
      } else if (to.name == "plan_projectlayers") {
        for (let i = 0; i < this.navPaths.length; i++) {
          const item = this.navPaths[i];
          if(item.path && item.path == "plan_projectlayers") {
            this.navPaths.splice(i+1,this.navPaths.length-(i+1));
            break;
          }
        }
      } else if (to.name == "projectpmslist") {
        for (let i = 0; i < this.navPaths.length; i++) {
          const item = this.navPaths[i];
          if(item.path && item.path == "projectpmslist") {
            this.navPaths.splice(i+1,this.navPaths.length-(i+1));
            break;
          }
        }
      }
    },
  },
  mounted() {
    this.routername = this.$route.name;
    this.init();
  },
  created() {},
};
</script>
