import Cookies from "js-cookie";
import $ from "jquery";
import _ from "lodash";
import Util from "./VR3D/Util/Util.js"

let service = {};
let pathexclude = ['/file/resume', '/file/check', '/sys/viewComment/save', '/progress/clean'];

service.request = function (method, url, data, success_callback, error_callback, options, fullUrl) {
  options = options ? options : {};

  $.ajax({
    url: url.indexOf('https:') !== -1 ? url : `${Baseurl}${url}`,
    data: (method != "get") ? JSON.stringify(data) : data,
    dataType: options.dataType ? options.dataType : "json",
    contentType: options.contentType,
    crossDomain: true,
    beforeSend: function (request) {
      request.setRequestHeader("accept-language", window.localStorage.language);

      if (method != "get") {
        request.setRequestHeader("Content-type", "application/json;charset=UTF-8");
      }

      if (Cookies.get("sdms_token")) {
        request.setRequestHeader("token", Cookies.get("sdms_token"));
      } else if (Cookies.get("workspace_token")) {
        request.setRequestHeader("token", Cookies.get("workspace_token"));
      }
    },
    xhrFields: {
      withCredentials: true
    },
    method: method,
    success: function (dt) {
      if (dt.code == 0) {
        success_callback(dt);
        if (dt.msg && method != "get") {
          if (!_.includes(pathexclude, url)) {
            // window.URAVue.$Message.success(window.URAVue.$t(dt.msg));
            // if (!options.skipSuccess)
            //   window.URAVue.$Message.success(dt.msg);
          }
        }
      } else if (dt.code == 401) {
        // logout
        /// HARDCODE
        console.log(window.URAVue.$route.name)
        if(window.URAVue.$route.name!='cdashboard') {
          window.URAVue.$emit("sys_err", window.URAVue.$t(dt.msg));
          window.URAVue.$Message.destroy();
          window.URAVue.$Message.error(dt.msg);
          Cookies.remove("sdms_token");
          window.URAVue.$router.push({
            name: "login"
          });
        }
      } else {
        if (error_callback) {
          error_callback(dt);
        }
        if (dt.msg) {
          // show err msg
          // window.URAVue.$emit("sys_err", window.URAVue.$t(dt.msg));
          if (!options.skipServerError) {
            window.URAVue.$emit("sys_err", dt.msg);
            window.URAVue.$Message.destroy();
            window.URAVue.$Message.error({
              content: dt.msg,
              duration: 5,
              closable: true
            });
          }
        }
      }
    },
    complete: function () {},
    error: function (err) {
      console.log(err);
      // show err msg
      if (!options.skipServerError)
        window.URAVue.$emit("sys_err", err);

      if (error_callback)
        error_callback(err);
    }
  });
}

service.requestFrom = function (method, url, data, success_callback, error_callback, options) {
  options = options ? options : {};

  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
      var dt = JSON.parse(xmlHttp.responseText);
      success_callback(dt);
      if (dt.msg) {
        if (!_.includes(pathexclude, url)) {
          // window.URAVue.$Message.success(window.URAVue.$t(dt.msg));
          if (!options.skipSuccess)
            window.URAVue.$Message.success(dt.msg);
        }
      }
    } else {
      if (xmlHttp.status >= 400) {
        error_callback();
      }
    }
  };
  xmlHttp.open(_.upperCase(method), `${Baseurl}${url}`, true); // true for asynchronous
  if (Cookies.get("sdms_token")) {
    xmlHttp.setRequestHeader(
      "token",
      Cookies.get("sdms_token")
    );
  } else if (Cookies.get("workspace_token")) {
    xmlHttp.setRequestHeader("token", Cookies.get("workspace_token"));
  }
  xmlHttp.setRequestHeader(
    "accept-language",
    window.localStorage.language
  );
  /* xmlHttp.setRequestHeader(
    "Content-Type",
    "multipart/form-data; charset=UTF-8"
    ); */
  xmlHttp.withCredentials = true;
  xmlHttp.send(data);
}

// service.requestBuffer = function (method, url, form, success_callback, error_callback) {
service.requestBuffer = function (method, url, json, success_callback, error_callback) {
  let keyForm;
  if (json) {
    let requestJSONStr = JSON.stringify(json);
    let keyBufferPromise = Util.streamTranslator(
      Util.getToken(),
      requestJSONStr
    );
    keyBufferPromise.then(keyBuffer => {
      keyForm = new FormData();
      keyForm.append("key", keyBuffer);
      sendRequest();
    });
  } else
    sendRequest();

  function sendRequest() {
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
        // success_callback(xmlHttp.response);
        callback(xmlHttp.response);
      else if (xmlHttp.status >= 400)
        error_callback();
    };
    xmlHttp.open(method, `${Baseurl}${url}`, true);
    if (Cookies.get("sdms_token")) {
      xmlHttp.setRequestHeader("token", Cookies.get("sdms_token"));
    } else if (Cookies.get("workspace_token")) {
      xmlHttp.setRequestHeader("token", Cookies.get("workspace_token"));
    }
    xmlHttp.setRequestHeader("accept-language", window.localStorage.language);

    xmlHttp.withCredentials = true;
    xmlHttp.responseType = 'arraybuffer';
    xmlHttp.send(keyForm);

    function callback(buffer) {
      let decryPromise = Util.streamTranslator(
        Util.getToken(),
        buffer
      );
      decryPromise.then(respStr => {
        try {
          respStr = JSON.parse(respStr);
        } catch (e) {
          console.log(e);
        }
        success_callback(respStr);
      });
    }
  }
}

service.requestFileBuffer = function (method, url, json, success_callback, error_callback) {
  let xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
      // success_callback(xmlHttp.response);
      success_callback(xmlHttp.response);
    else if (xmlHttp.status >= 400)
      error_callback();
  };
  xmlHttp.open(method, `${Baseurl}${url}`, true);
  if (Cookies.get("sdms_token")) {
    xmlHttp.setRequestHeader("token", Cookies.get("sdms_token"));
  } else if (Cookies.get("workspace_token")) {
    xmlHttp.setRequestHeader("token", Cookies.get("workspace_token"));
  }
  xmlHttp.setRequestHeader("accept-language", window.localStorage.language);
  xmlHttp.setRequestHeader("Content-type", "application/json");

  xmlHttp.withCredentials = true;
  xmlHttp.responseType = 'arraybuffer';
  xmlHttp.send(JSON.stringify(json));
}

service.resetPwd = function (currentPwd, newPwd, newPwdDup) {
  return new Promise(function (resolve, reject) {
    if (newPwd != newPwdDup && newPwd.length > 0) {
      resolve({
        isReset: false,
        msg: 'x_signup_repwd_error'
      });
    } else {
      let pwdResetObj = {
        "password": currentPwd,
        "newPassword": newPwd
      };

      service.request("put", "/sys/user/password", pwdResetObj, function (dt) {
        if (dt.code >= 400) {
          resolve({
            isReset: false,
            msg: dt.msg
          });
        } else {
          resolve({
            isReset: true
          });
        }
      }, function (dt) {
        resolve({
          isReset: false // service.js already pumping the alert, do not need to tell the promise to do so again
        });
      });
    }
  });
}

export default service;